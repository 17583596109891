import React from "react";
import { useStakingContext } from "./context/StakingContext";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import useChain from "../../hooks/useChain";

function SableBnbLpBalanceLabel() {
  const {
    userStakingData: { lpBalance: sableBnbLpBalance }
  } = useStakingContext();
  const chain = useChain();
  const lpSymbol = chain.isStakeLPSupported ? `SABLE-${chain.nativeCurrency.symbol}` : "SABLE";

  return <BalanceLabel balance={sableBnbLpBalance} tokenSymbol={lpSymbol} />;
}

export default SableBnbLpBalanceLabel;
