import React, { createContext, useContext, useEffect, useState } from "react";
import { useLiquity } from "../../../hooks/LiquityContext";
import { _InternalEthersLiquityConnection } from "@liquity/lib-ethers/dist/src/EthersLiquityConnection";
import { BigNumber, ContractTransaction, Signer, ethers, utils } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import usePancakeSwapPool2Data, {
  PancakeSwapPool2Data
} from "../../../hooks/usePancakeSwapPool2Data";
import useChain from "../../../hooks/useChain";

const getLpContract = (lpAddress: string, provider: ethers.providers.Provider | Signer) => {
  const contract = new ethers.Contract(
    lpAddress,
    [
      "function balanceOf(address owner) view returns (uint256)",
      "function allowance(address owner, address spender) external view returns (uint)",
      "function approve(address spender, uint value) external returns (bool)",
      "event Approval(address indexed owner, address indexed spender, uint256 value)",
      "event Transfer(address indexed from, address indexed to, uint256 value)"
    ],
    provider
  );

  return contract;
};
type UserStakingData = {
  lpBalance: string;
  lpAllowanceForStakingContract: string;
};

type ContextType = {
  lpAddress: string | null;
  userStakingData: UserStakingData;
  isApproving: boolean;
  pancakeSwapPool2Data: PancakeSwapPool2Data;
  approveStakingContractToSpendLp: () => Promise<void>;
};

const initialContextState: ContextType = {
  lpAddress: null,
  userStakingData: {
    lpBalance: "0",
    lpAllowanceForStakingContract: "0"
  },
  isApproving: false,
  pancakeSwapPool2Data: {} as PancakeSwapPool2Data,
  approveStakingContractToSpendLp: () => Promise.resolve()
};
const StakingContext = createContext<ContextType>(initialContextState);

export const useStakingContext = () => {
  return useContext(StakingContext);
};

function StakingProvider({ children }: { children: React.ReactElement }) {
  const chain = useChain();
  const [lpAddress, setLpAddress] = useState(initialContextState.lpAddress);
  const [userStakingData, setUserStakingData] = useState<UserStakingData>(
    initialContextState.userStakingData
  );
  const [isApproving, setIsApproving] = useState(initialContextState.isApproving);
  const { library: provider } = useWeb3React<Web3Provider>();
  const { liquity, account } = useLiquity();
  const connection = liquity.connection as _InternalEthersLiquityConnection;
  const pancakeSwapPool2Data = usePancakeSwapPool2Data();
  const approveStakingContractToSpendLp = async () => {
    setIsApproving(true);
    const signer = provider?.getSigner(account);
    if (!signer || !lpAddress) return;
    const lpContract = getLpContract(lpAddress, signer);
    try {
      const transaction = (await lpContract.approve(
        connection.addresses.sableStaking,
        ethers.constants.MaxUint256
      )) as ContractTransaction;
      await transaction.wait();
    } catch (error) {
      console.log(error);
    } finally {
      setIsApproving(false);
    }
  };

  /** Fetch data once */
  useEffect(() => {
    const getData = async () => {
      const { sableStaking: sableStakingContract } = connection._contracts;
      try {
        const lpAddress = chain.isStakeLPSupported
          ? await sableStakingContract.sableLPToken()
          : connection.addresses.sableToken;
        if (lpAddress && provider) {
          setLpAddress(lpAddress);
          const lpContract = getLpContract(lpAddress, provider);
          const userLpBalance = (await lpContract.balanceOf(account)) as BigNumber;
          const lpAllowanceForStakingContract = (await lpContract.allowance(
            account,
            connection.addresses.sableStaking
          )) as BigNumber;
          /** Assume sable lp decimal is 18 d.p */
          setUserStakingData({
            lpBalance: utils.formatEther(userLpBalance),
            lpAllowanceForStakingContract: utils.formatEther(lpAllowanceForStakingContract)
          });
        }
      } catch (error) {
        console.log(error);
        setLpAddress(initialContextState.lpAddress);
        setUserStakingData(initialContextState.userStakingData);
      }
    };
    getData();
  }, [
    account,
    chain.isStakeLPSupported,
    connection._contracts,
    connection.addresses.sableStaking,
    connection.addresses.sableToken,
    provider
  ]);

  // Listen on Approval event
  useEffect(() => {
    if (!lpAddress || !provider) return;
    const lpContract = getLpContract(lpAddress, provider);
    const filter = lpContract.filters.Approval(account, connection.addresses.sableStaking);
    const eventHandler = async () => {
      try {
        const lpAllowanceForStakingContract = (await lpContract.allowance(
          account,
          connection.addresses.sableStaking
        )) as BigNumber;
        setUserStakingData(prev => ({
          ...prev,
          lpAllowanceForStakingContract: utils.formatEther(lpAllowanceForStakingContract)
        }));
      } catch (error) {
        console.log(error);
      }
    };
    lpContract.on(filter, eventHandler);

    return () => {
      lpContract.off(filter, eventHandler);
    };
  }, [account, connection.addresses.sableStaking, provider, lpAddress]);

  // Listen on Transfer event
  useEffect(() => {
    if (!lpAddress || !provider) return;
    const lpContract = getLpContract(lpAddress, provider);
    const filterFromUserAccount = lpContract.filters.Transfer(account);
    const filterToUserAccount = lpContract.filters.Transfer(null, account);
    const eventHandler = async () => {
      try {
        const lpBalance = (await lpContract.balanceOf(account)) as BigNumber;
        setUserStakingData(prev => ({
          ...prev,
          lpBalance: utils.formatEther(lpBalance)
        }));
      } catch (error) {
        console.log(error);
      }
    };
    lpContract.on(filterFromUserAccount, eventHandler);
    lpContract.on(filterToUserAccount, eventHandler);

    return () => {
      lpContract.off(filterFromUserAccount, eventHandler);
      lpContract.off(filterToUserAccount, eventHandler);
    };
  }, [account, provider, lpAddress]);

  return (
    <StakingContext.Provider
      value={{
        lpAddress: lpAddress,
        userStakingData,
        isApproving,
        pancakeSwapPool2Data,
        approveStakingContractToSpendLp
      }}
    >
      {children}
    </StakingContext.Provider>
  );
}

export default StakingProvider;
