import React, { useCallback, useEffect, useState, useRef } from "react";
import { Flex, Button, Box, Card, Heading } from "theme-ui";
import {
  LiquityStoreState,
  Decimal,
  Trove,
  Difference,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useStableTroveChange } from "../../hooks/useStableTroveChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { TroveAction } from "./TroveAction";
import { useTroveView } from "./context/TroveViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import { ExpensiveTroveChangeWarning, GasEstimationState } from "./ExpensiveTroveChangeWarning";
import {
  selectForTroveChangeValidation,
  systemStateForTroveChangeValidation,
  validateTroveChange
} from "./validation/validateTroveChange";
import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import { BORROWING_FEE_TOOLTIP, LIQUIDATION_RESERVE_TOOLTIP } from "./constants";
import useChain from "../../hooks/useChain";

const selector = (state: LiquityStoreState) => {
  const { trove, fees, price, accountBalance } = state;
  return {
    trove,
    fees,
    price,
    accountBalance,
    validationContext: selectForTroveChangeValidation(state)
  };
};

const selectSystemState = (state: BlockPolledLiquityStoreState) => {
  const { usdsGasCompensation, minNetDebt, borrowingFeeFloor } = state;
  return {
    usdsGasCompensation,
    minNetDebt,
    borrowingFeeFloor,
    systemState: systemStateForTroveChangeValidation(state)
  };
};

const TRANSACTION_ID = "trove-adjustment";
const GAS_ROOM_BNB = Decimal.from(0.1);

const MAX_ORACLE_RATE = Decimal.from(0.0025);

const feeFrom = (original: Trove, edited: Trove, borrowingRate: Decimal): Decimal => {
  const change = original.whatChanged(edited, borrowingRate);

  if (change && change.type !== "invalidCreation" && change.params.borrowUSDS) {
    return change.params.borrowUSDS.mul(borrowingRate);
  } else {
    return Decimal.ZERO;
  }
};

const applyUnsavedCollateralChanges = (unsavedChanges: Difference, trove: Trove) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return trove.collateral.add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(trove.collateral)) {
        return trove.collateral.sub(unsavedChanges.absoluteValue);
      }
    }
    return trove.collateral;
  }
  return trove.collateral;
};

const applyUnsavedNetDebtChanges = (
  unsavedChanges: Difference,
  trove: Trove,
  usdsGasCompensation: Decimal
) => {
  if (unsavedChanges.absoluteValue) {
    if (unsavedChanges.positive) {
      return trove.netDebt(usdsGasCompensation).add(unsavedChanges.absoluteValue);
    }
    if (unsavedChanges.negative) {
      if (unsavedChanges.absoluteValue.lt(trove.netDebt(usdsGasCompensation))) {
        return trove.netDebt(usdsGasCompensation).sub(unsavedChanges.absoluteValue);
      }
    }
    return trove.netDebt;
  }
  return trove.netDebt;
};

export const Adjusting: React.FC = () => {
  const { dispatchEvent } = useTroveView();
  const { fees, trove, price, accountBalance, validationContext } = useLiquitySelector(selector);
  const { usdsGasCompensation, systemState, borrowingFeeFloor } =
    useLiquitySelector(selectSystemState);
  const editingState = useState<string>();
  const previousTrove = useRef<Trove>(trove);

  // params changes
  const USDS_LIQUIDATION_RESERVE = usdsGasCompensation;

  const [collateral, setCollateral] = useState<Decimal>(trove.collateral);
  const [netDebt, setNetDebt] = useState<Decimal>(trove.netDebt(USDS_LIQUIDATION_RESERVE));

  const transactionState = useMyTransactionState(TRANSACTION_ID);
  const borrowingRate = fees.borrowingRate(borrowingFeeFloor);

  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      dispatchEvent("TROVE_ADJUSTED");
    }
  }, [transactionState.type, dispatchEvent]);

  useEffect(() => {
    if (!previousTrove.current.collateral.eq(trove.collateral)) {
      const unsavedChanges = Difference.between(collateral, previousTrove.current.collateral);
      const nextCollateral = applyUnsavedCollateralChanges(unsavedChanges, trove);
      setCollateral(nextCollateral);
    }
    if (
      !previousTrove.current
        .netDebt(USDS_LIQUIDATION_RESERVE)
        .eq(trove.netDebt(USDS_LIQUIDATION_RESERVE))
    ) {
      const unsavedChanges = Difference.between(
        netDebt,
        previousTrove.current.netDebt(USDS_LIQUIDATION_RESERVE)
      );
      const nextNetDebt = applyUnsavedNetDebtChanges(
        unsavedChanges,
        trove,
        USDS_LIQUIDATION_RESERVE
      );
      setNetDebt(nextNetDebt);
    }
    previousTrove.current = trove;
  }, [trove, collateral, netDebt, USDS_LIQUIDATION_RESERVE]);

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_TROVE_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(trove.collateral);
    setNetDebt(trove.netDebt(USDS_LIQUIDATION_RESERVE));
  }, [trove, USDS_LIQUIDATION_RESERVE]);

  const isDirty =
    !collateral.eq(trove.collateral) || !netDebt.eq(trove.netDebt(USDS_LIQUIDATION_RESERVE));
  const isDebtIncrease = netDebt.gt(trove.netDebt(USDS_LIQUIDATION_RESERVE));
  const debtIncreaseAmount = isDebtIncrease
    ? netDebt.sub(trove.netDebt(USDS_LIQUIDATION_RESERVE))
    : Decimal.ZERO;

  const fee = isDebtIncrease
    ? feeFrom(trove, new Trove(trove.collateral, trove.debt.add(debtIncreaseAmount)), borrowingRate)
    : Decimal.ZERO;
  const maxBorrowingFee = isDebtIncrease
    ? feeFrom(
        trove,
        new Trove(trove.collateral, trove.debt.add(debtIncreaseAmount)),
        MAX_ORACLE_RATE
      )
    : Decimal.ZERO;
  const totalDebt = netDebt.add(Decimal.from(USDS_LIQUIDATION_RESERVE)).add(fee);
  const maxTotalDebt = netDebt.add(Decimal.from(USDS_LIQUIDATION_RESERVE)).add((fee.add(maxBorrowingFee)));
  const maxBorrowingRate = borrowingRate.add(0.005);
  const updatedTrove = isDirty ? new Trove(collateral, totalDebt) : trove;
  const availableEth = accountBalance.gt(GAS_ROOM_BNB)
    ? accountBalance.sub(GAS_ROOM_BNB)
    : Decimal.ZERO;
  const maxCollateral = trove.collateral.add(availableEth);
  const collateralMaxedOut = collateral.eq(maxCollateral);
  const collateralRatio =
    !collateral.isZero && !netDebt.isZero ? updatedTrove.collateralRatio(price) : undefined;
  const collateralRatioChange = Difference.between(collateralRatio, trove.collateralRatio(price));

  const [troveChange, description] = validateTroveChange(
    trove,
    updatedTrove,
    borrowingRate,
    validationContext,
    systemState
  );

  const stableTroveChange = useStableTroveChange(troveChange);
  const [gasEstimationState, setGasEstimationState] = useState<GasEstimationState>({ type: "idle" });

  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  if (trove.status !== "open") {
    return null;
  }

  return (
    <Card>
      <Heading>
        Trove
        {isDirty && !isTransactionPending && (
          <Button variant="titleIcon" sx={{ ":enabled:hover": { color: "danger" } }} onClick={reset}>
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Heading>

      <Box sx={{ p: [2, 3] }}>
        <EditableRow
          label="Collateral"
          trailingLabel={
            <BalanceLabel balance={accountBalance.toString()} tokenSymbol={currencySymbol} />
          }
          inputId="trove-collateral"
          amount={collateral.prettify(4)}
          maxAmount={maxCollateral.toString()}
          maxedOut={collateralMaxedOut}
          editingState={editingState}
          unit={currencySymbol}
          editedAmount={collateral.toString(4)}
          setEditedAmount={(amount: string) => setCollateral(Decimal.from(amount))}
        />

        <EditableRow
          label="Net debt"
          inputId="trove-net-debt-amount"
          amount={netDebt.prettify()}
          unit={COIN}
          editingState={editingState}
          editedAmount={netDebt.toString(2)}
          setEditedAmount={(amount: string) => setNetDebt(Decimal.from(amount))}
        />

        <StaticRow
          label="Liquidation Reserve"
          inputId="trove-liquidation-reserve"
          amount={`${Decimal.from(USDS_LIQUIDATION_RESERVE).toString(0)}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "200px" }}>
                  {LIQUIDATION_RESERVE_TOOLTIP}
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Borrowing Fee"
          inputId="trove-borrowing-fee"
          amount={`Max. ${(fee.add(maxBorrowingFee)).prettify(2)}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  {BORROWING_FEE_TOOLTIP}
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Total debt"
          inputId="trove-total-debt"
          amount={
            totalDebt.eq(maxTotalDebt)
              ? totalDebt.prettify(2)
              : `${totalDebt.prettify(2)} - ${maxTotalDebt.prettify(2)}`
          }
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  The total amount of USDS your Trove will hold.{" "}
                  {isDirty && (
                    <>
                      You will need to repay{" "}
                      up to{" "}
                      {maxTotalDebt.sub(Decimal.from(USDS_LIQUIDATION_RESERVE)).prettify(2)} USDS to
                      reclaim your collateral ({USDS_LIQUIDATION_RESERVE.toString()} USDS Liquidation
                      Reserve excluded).
                    </>
                  )}
                </Card>
              }
            />
          }
        />

        <CollateralRatio value={collateralRatio} change={collateralRatioChange} />

        {description ?? (
          <ActionDescription>
            Adjust your Trove by modifying its collateral, debt, or both.
          </ActionDescription>
        )}

        <ExpensiveTroveChangeWarning
          troveChange={stableTroveChange}
          maxBorrowingRate={maxBorrowingRate}
          borrowingFeeDecayToleranceMinutes={60}
          gasEstimationState={gasEstimationState}
          setGasEstimationState={setGasEstimationState}
        />

        <Flex variant="layout.actions">
          <Button variant="cancel" onClick={handleCancelPressed}>
            Cancel
          </Button>

          {stableTroveChange ? (
            <TroveAction
              transactionId={TRANSACTION_ID}
              change={stableTroveChange}
              maxBorrowingRate={maxBorrowingRate}
              borrowingFeeDecayToleranceMinutes={60}
            >
              Confirm
            </TroveAction>
          ) : (
            <Button disabled>Confirm</Button>
          )}
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
