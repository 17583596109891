import React from "react";
import { Flex } from "theme-ui";
import { InfoIcon } from "./InfoIcon";
import type { Lexicon } from "../lexicon";

type StatisticProps = {
  lexicon: Lexicon;
  children?: React.ReactNode;
};

export const Statistic: React.FC<StatisticProps> = ({ lexicon, children }) => {
  return (
    <Flex sx={{ borderBottom: 1, borderColor: "#804C3B", paddingLeft: 2 }}>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "flex-start",
          flex: 1.2,
          fontWeight: 400,
          fontSize: 2,
          color: "#000000",
          pt: "8px",
          pb: "4px"
        }}
      >
        <Flex>{lexicon.term}</Flex>
        {lexicon.term && lexicon.description && (
          <InfoIcon size="xs" tooltip={lexicon.description} link={lexicon.link} />
        )}
      </Flex>
      <Flex
        sx={{
          justifyContent: "flex-end",
          flex: 1,
          alignItems: "center",
          fontWeight: 400,
          fontSize: 2,
          color: "#000000"
        }}
      >
        {children}
      </Flex>
    </Flex>
  );
};
