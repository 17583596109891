/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BigNumber } from "@ethersproject/bignumber";
import { Event } from "@ethersproject/contracts";

import {
  Decimal,
  ObservableLiquity,
  StabilityDeposit,
  Trove,
  TroveWithPendingRedistribution
} from "@liquity/lib-base";

import { _getContracts, _requireAddress } from "./EthersLiquityConnection";
import { ReadableEthersLiquity } from "./ReadableEthersLiquity";

const debouncingDelayMs = 50;

const debounce = (listener: (latestBlock: number) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined = undefined;
  let latestBlock = 0;

  return (...args: unknown[]) => {
    const event = args[args.length - 1] as Event;

    if (event.blockNumber !== undefined && event.blockNumber > latestBlock) {
      latestBlock = event.blockNumber;
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      listener(latestBlock);
      timeoutId = undefined;
    }, debouncingDelayMs);
  };
};

/** @alpha */
export class ObservableEthersLiquity implements ObservableLiquity {
  private readonly _readable: ReadableEthersLiquity;

  constructor(readable: ReadableEthersLiquity) {
    this._readable = readable;
  }

  watchTotalRedistributed(
    onTotalRedistributedChanged: (totalRedistributed: Trove) => void
  ): () => void {
    const { activePool, defaultPool } = _getContracts(this._readable.connection);
    const etherSent = activePool.filters.EtherSent();

    const redistributionListener = debounce((blockTag: number) => {
      this._readable.getTotalRedistributed({ blockTag }).then(onTotalRedistributedChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      // @ts-ignore
      if (toAddress === defaultPool.address) {
        redistributionListener(event);
      }
    };
    // @ts-ignore

    activePool.on(etherSent, etherSentListener);

    return () => {
      // @ts-ignore
      activePool.removeListener(etherSent, etherSentListener);
    };
  }

  watchTroveWithoutRewards(
    onTroveChanged: (trove: TroveWithPendingRedistribution) => void,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { troveManager, borrowerOperations } = _getContracts(this._readable.connection);
    const troveUpdatedByTroveManager = troveManager.filters.TroveUpdated(address);
    const troveUpdatedByBorrowerOperations = borrowerOperations.filters.TroveUpdated(address);

    const troveListener = debounce((blockTag: number) => {
      this._readable.getTroveBeforeRedistribution(address, { blockTag }).then(onTroveChanged);
    });
    // @ts-ignore
    troveManager.on(troveUpdatedByTroveManager, troveListener);
    // @ts-ignore
    borrowerOperations.on(troveUpdatedByBorrowerOperations, troveListener);

    return () => {
      // @ts-ignore
      troveManager.removeListener(troveUpdatedByTroveManager, troveListener);
      // @ts-ignore
      borrowerOperations.removeListener(troveUpdatedByBorrowerOperations, troveListener);
    };
  }

  watchNumberOfTroves(onNumberOfTrovesChanged: (numberOfTroves: number) => void): () => void {
    const { troveManager } = _getContracts(this._readable.connection);
    const { TroveUpdated } = troveManager.filters;
    const troveUpdated = TroveUpdated();

    const troveUpdatedListener = debounce((blockTag: number) => {
      this._readable.getNumberOfTroves({ blockTag }).then(onNumberOfTrovesChanged);
    });

    // @ts-ignore
    troveManager.on(troveUpdated, troveUpdatedListener);

    return () => {
      // @ts-ignore
      troveManager.removeListener(troveUpdated, troveUpdatedListener);
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  watchPrice(onPriceChanged: (price: Decimal) => void): () => void {
    // TODO revisit
    // We no longer have our own PriceUpdated events. If we want to implement this in an event-based
    // manner, we'll need to listen to aggregator events directly. Or we could do polling.
    throw new Error("Method not implemented.");
  }

  watchTotal(onTotalChanged: (total: Trove) => void): () => void {
    const { troveManager } = _getContracts(this._readable.connection);
    const { TroveUpdated } = troveManager.filters;
    const troveUpdated = TroveUpdated();

    const totalListener = debounce((blockTag: number) => {
      this._readable.getTotal({ blockTag }).then(onTotalChanged);
    });
    // @ts-ignore
    troveManager.on(troveUpdated, totalListener);

    return () => {
      // @ts-ignore
      troveManager.removeListener(troveUpdated, totalListener);
    };
  }

  watchStabilityDeposit(
    onStabilityDepositChanged: (stabilityDeposit: StabilityDeposit) => void,
    address?: string
  ): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { activePool, stabilityPool } = _getContracts(this._readable.connection);
    const { UserDepositChanged } = stabilityPool.filters;
    const { EtherSent } = activePool.filters;

    const userDepositChanged = UserDepositChanged(address);
    const etherSent = EtherSent();

    const depositListener = debounce((blockTag: number) => {
      this._readable.getStabilityDeposit(address, { blockTag }).then(onStabilityDepositChanged);
    });

    const etherSentListener = (toAddress: string, _amount: BigNumber, event: Event) => {
      // @ts-ignore
      if (toAddress === stabilityPool.address) {
        // Liquidation while Stability Pool has some deposits
        // There may be new gains
        depositListener(event);
      }
    };

    // @ts-ignore
    stabilityPool.on(userDepositChanged, depositListener);
    // @ts-ignore
    activePool.on(etherSent, etherSentListener);

    return () => {
      // @ts-ignore
      stabilityPool.removeListener(userDepositChanged, depositListener);
      // @ts-ignore
      activePool.removeListener(etherSent, etherSentListener);
    };
  }

  watchUSDSInStabilityPool(
    onUSDSInStabilityPoolChanged: (usdsInStabilityPool: Decimal) => void
  ): () => void {
    const { usdsToken, stabilityPool } = _getContracts(this._readable.connection);
    const { Transfer } = usdsToken.filters;
    // @ts-ignore
    const transferUSDSFromStabilityPool = Transfer(stabilityPool.address);
    // @ts-ignore
    const transferUSDSToStabilityPool = Transfer(null, stabilityPool.address);

    const stabilityPoolUSDSFilters = [transferUSDSFromStabilityPool, transferUSDSToStabilityPool];

    const stabilityPoolUSDSListener = debounce((blockTag: number) => {
      this._readable.getUSDSInStabilityPool({ blockTag }).then(onUSDSInStabilityPoolChanged);
    });

    // @ts-ignore
    stabilityPoolUSDSFilters.forEach(filter => usdsToken.on(filter, stabilityPoolUSDSListener));

    return () =>
      stabilityPoolUSDSFilters.forEach(filter =>
        // @ts-ignore
        usdsToken.removeListener(filter, stabilityPoolUSDSListener)
      );
  }

  watchUSDSBalance(onUSDSBalanceChanged: (balance: Decimal) => void, address?: string): () => void {
    address ??= _requireAddress(this._readable.connection);

    const { usdsToken } = _getContracts(this._readable.connection);
    const { Transfer } = usdsToken.filters;
    const transferUSDSFromUser = Transfer(address);
    const transferUSDSToUser = Transfer(null, address);

    const usdsTransferFilters = [transferUSDSFromUser, transferUSDSToUser];

    const usdsTransferListener = debounce((blockTag: number) => {
      this._readable.getUSDSBalance(address, { blockTag }).then(onUSDSBalanceChanged);
    });
    // @ts-ignore
    usdsTransferFilters.forEach(filter => usdsToken.on(filter, usdsTransferListener));

    return () =>
      // @ts-ignore
      usdsTransferFilters.forEach(filter => usdsToken.removeListener(filter, usdsTransferListener));
  }
}
