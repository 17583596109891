import React from "react";
import { Box, Image } from "theme-ui";

type LiquityLogoProps = React.ComponentProps<typeof Box> & {
  height?: number | string;
};

export const LiquityLogo: React.FC<LiquityLogoProps> = ({ height, ...boxProps }) => (
  <Box sx={{ lineHeight: 0 }} {...boxProps}>
    <Image
      src="./sable-logo-icon.svg"
      sx={{
        height,
        "@media screen and (max-width: 47.9375em)": {
          display: "none"
        }
      }}
    />
    <Image
      src="./sable-logo-mobile.svg"
      sx={{
        marginLeft: "8px",
        "@media screen and (min-width: 48em)": {
          display: "none"
        }
      }}
    />
  </Box>
);
