import { Decimal, Decimalish } from "./Decimal";

/**
 * Represents the change between two states of an SABLE Stake.
 *
 * @public
 */
export type SABLEStakeChange<T> =
  | { stakeSABLE: T; unstakeSABLE?: undefined }
  | { stakeSABLE?: undefined; unstakeSABLE: T; unstakeAllSABLE: boolean };

/** 
 * Represents a user's SABLE stake and accrued gains.
 * 
 * @remarks
 * Returned by the {@link ReadableLiquity.getSABLEStake | getSABLEStake()} function.

 * @public
 */
export class SABLEStake {
  /** The amount of SABLE that's staked. */
  readonly stakedSABLE: Decimal;

  /** Collateral gain available to withdraw. */
  readonly collateralGain: Decimal;

  /** USDS gain available to withdraw. */
  readonly usdsGain: Decimal;

  readonly sableGain: Decimal;

  /** @internal */
  constructor(stakedSABLE = Decimal.ZERO, collateralGain = Decimal.ZERO, usdsGain = Decimal.ZERO, sableGain = Decimal.ZERO) {
    this.stakedSABLE = stakedSABLE;
    this.collateralGain = collateralGain;
    this.usdsGain = usdsGain;
    this.sableGain = sableGain;
  }

  get isEmpty(): boolean {
    return this.stakedSABLE.isZero && this.collateralGain.isZero && this.usdsGain.isZero && this.sableGain.isZero;
  }

  /** @internal */
  toString(): string {
    return (
      `{ stakedSABLE: ${this.stakedSABLE}` +
      `, collateralGain: ${this.collateralGain}` +
      `, usdsGain: ${this.usdsGain} }` +
      `, sableGain: ${this.sableGain}`
    );
  }

  /**
   * Compare to another instance of `SABLEStake`.
   */
  equals(that: SABLEStake): boolean {
    return (
      this.stakedSABLE.eq(that.stakedSABLE) &&
      this.collateralGain.eq(that.collateralGain) &&
      this.usdsGain.eq(that.usdsGain) &&
      this.sableGain.eq(that.sableGain)
    );
  }

  /**
   * Calculate the difference between this `SABLEStake` and `thatStakedSABLE`.
   *
   * @returns An object representing the change, or `undefined` if the staked amounts are equal.
   */
  whatChanged(thatStakedSABLE: Decimalish): SABLEStakeChange<Decimal> | undefined {
    thatStakedSABLE = Decimal.from(thatStakedSABLE);

    if (thatStakedSABLE.lt(this.stakedSABLE)) {
      return {
        unstakeSABLE: this.stakedSABLE.sub(thatStakedSABLE),
        unstakeAllSABLE: thatStakedSABLE.isZero
      };
    }

    if (thatStakedSABLE.gt(this.stakedSABLE)) {
      return { stakeSABLE: thatStakedSABLE.sub(this.stakedSABLE) };
    }
  }

  /**
   * Apply a {@link SABLEStakeChange} to this `SABLEStake`.
   *
   * @returns The new staked SABLE amount.
   */
  apply(change: SABLEStakeChange<Decimalish> | undefined): Decimal {
    if (!change) {
      return this.stakedSABLE;
    }

    if (change.unstakeSABLE !== undefined) {
      return change.unstakeAllSABLE || this.stakedSABLE.lte(change.unstakeSABLE)
        ? Decimal.ZERO
        : this.stakedSABLE.sub(change.unstakeSABLE);
    } else {
      return this.stakedSABLE.add(change.stakeSABLE);
    }
  }
}
