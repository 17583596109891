import { useEffect, useState } from "react";
import { utils } from "ethers";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { fetchSABLEPrice } from "../components/Stability/context/fetchSABLEPrice";
import { ChainId } from "../config/web3/chains";
import { useWeb3React } from "@web3-react/core";
import { useContract } from "./useContract";
import { getCommunityIssuanceAddr } from "../config/addresses";

const selector = ({ usdsInStabilityPool }: LiquityStoreState) => ({
  usdsInStabilityPool
});

function useSPRewardsAPR() {
  const { chainId } = useWeb3React();
  const [rewardPerSec, setReward] = useState<number | undefined>(undefined);
  const [sablePrice, setSablePrice] = useState<Decimal | undefined>(undefined);
  const { usdsInStabilityPool } = useLiquitySelector(selector);
  const communityIssuanceContract = useContract<any>(getCommunityIssuanceAddr(chainId as ChainId), [
    "function latestRewardPerSec() returns (uint)"
  ]);

  const rewardPerYear = rewardPerSec !== undefined ? rewardPerSec * 86400 * 365 : 0;
  const rewardPerYearInUSD = sablePrice !== undefined ? rewardPerYear * Number(sablePrice) : 0;

  const stabilityPoolAPR = !usdsInStabilityPool.isZero
    ? ((rewardPerYearInUSD / Number(usdsInStabilityPool)) * 100).toFixed(2)
    : "0";

  useEffect(() => {
    (async () => {
      try {
        const { sablePriceUSD } = await fetchSABLEPrice();
        setSablePrice(sablePriceUSD);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    const fetchExchangeData = async () => {
      try {
        if (communityIssuanceContract[0]) {
          const rewardPerSec = await communityIssuanceContract[0].callStatic.latestRewardPerSec();
          const rewardNumber = parseFloat(utils.formatUnits(rewardPerSec, 18));
          setReward(rewardNumber);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchExchangeData();

    const interval = setInterval(fetchExchangeData, 600000); // Fetch data every 10 minute

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, [communityIssuanceContract]);

  return { stabilityPoolAPR }; // Return the reward state
}

export default useSPRewardsAPR;
