import { Button, Spinner } from "theme-ui";

import { Decimal, SABLEStakeChange } from "@liquity/lib-base";

import { useLiquity } from "../../hooks/LiquityContext";
import { useTransactionFunction } from "../Transaction";
import { useStakingContext } from "./context/StakingContext";

type StakingActionProps = {
  change: SABLEStakeChange<Decimal>;
  children?: React.ReactNode;
};

export const StakingManagerAction: React.FC<StakingActionProps> = ({ change, children }) => {
  const { liquity } = useLiquity();
  const {
    userStakingData: { lpAllowanceForStakingContract: sableBnbLpAllowanceForStakingContract },
    isApproving,
    approveStakingContractToSpendLp
  } = useStakingContext();
  const [sendTransaction] = useTransactionFunction(
    "stake",
    change.stakeSABLE
      ? liquity.send.stakeSABLE.bind(liquity.send, change.stakeSABLE)
      : liquity.send.unstakeSABLE.bind(liquity.send, change.unstakeSABLE)
  );
  const isUnstakeLp = !change.stakeSABLE;
  const hasAllowanceOnStakeLp = change.stakeSABLE?.lte(sableBnbLpAllowanceForStakingContract);
  const canStakeOrUnstake = hasAllowanceOnStakeLp || isUnstakeLp;
  return (
    <Button
      onClick={canStakeOrUnstake ? sendTransaction : approveStakingContractToSpendLp}
      disabled={isApproving}
    >
      {canStakeOrUnstake ? (
        children
      ) : isApproving ? (
        <>
          Approving <Spinner size={16} ml="2px" />
        </>
      ) : (
        "Approve"
      )}
    </Button>
  );
};
