import { Decimal, Decimalish } from "./Decimal";

/**
 * Represents the change between two Stability Deposit states.
 *
 * @public
 */
export type StabilityDepositChange<T> =
  | { depositUSDS: T; withdrawUSDS?: undefined }
  | { depositUSDS?: undefined; withdrawUSDS: T; withdrawAllUSDS: boolean };

/**
 * A Stability Deposit and its accrued gains.
 *
 * @public
 */
export class StabilityDeposit {
  /** Amount of USDS in the Stability Deposit at the time of the last direct modification. */
  readonly initialUSDS: Decimal;

  /** Amount of USDS left in the Stability Deposit. */
  readonly currentUSDS: Decimal;

  /** Amount of native currency (e.g. Ether) received in exchange for the used-up USDS. */
  readonly collateralGain: Decimal;

  /** Amount of SABLE rewarded since the last modification of the Stability Deposit. */
  readonly sableReward: Decimal;

  /**
   * Address of frontend through which this Stability Deposit was made.
   *
   * @remarks
   * If the Stability Deposit was made through a frontend that doesn't tag deposits, this will be
   * the zero-address.
   */
  readonly frontendTag: string;

  /** @internal */
  constructor(
    initialUSDS: Decimal,
    currentUSDS: Decimal,
    collateralGain: Decimal,
    sableReward: Decimal,
    frontendTag: string
  ) {
    this.initialUSDS = initialUSDS;
    this.currentUSDS = currentUSDS;
    this.collateralGain = collateralGain;
    this.sableReward = sableReward;
    this.frontendTag = frontendTag;

    if (this.currentUSDS.gt(this.initialUSDS)) {
      throw new Error("currentUSDS can't be greater than initialUSDS");
    }
  }

  get isEmpty(): boolean {
    return (
      this.initialUSDS.isZero &&
      this.currentUSDS.isZero &&
      this.collateralGain.isZero &&
      this.sableReward.isZero
    );
  }

  /** @internal */
  toString(): string {
    return (
      `{ initialUSDS: ${this.initialUSDS}` +
      `, currentUSDS: ${this.currentUSDS}` +
      `, collateralGain: ${this.collateralGain}` +
      `, sableReward: ${this.sableReward}` +
      `, frontendTag: "${this.frontendTag}" }`
    );
  }

  /**
   * Compare to another instance of `StabilityDeposit`.
   */
  equals(that: StabilityDeposit): boolean {
    return (
      this.initialUSDS.eq(that.initialUSDS) &&
      this.currentUSDS.eq(that.currentUSDS) &&
      this.collateralGain.eq(that.collateralGain) &&
      this.sableReward.eq(that.sableReward) &&
      this.frontendTag === that.frontendTag
    );
  }

  /**
   * Calculate the difference between the `currentUSDS` in this Stability Deposit and `thatUSDS`.
   *
   * @returns An object representing the change, or `undefined` if the deposited amounts are equal.
   */
  whatChanged(thatUSDS: Decimalish): StabilityDepositChange<Decimal> | undefined {
    thatUSDS = Decimal.from(thatUSDS);

    if (thatUSDS.lt(this.currentUSDS)) {
      return { withdrawUSDS: this.currentUSDS.sub(thatUSDS), withdrawAllUSDS: thatUSDS.isZero };
    }

    if (thatUSDS.gt(this.currentUSDS)) {
      return { depositUSDS: thatUSDS.sub(this.currentUSDS) };
    }
  }

  /**
   * Apply a {@link StabilityDepositChange} to this Stability Deposit.
   *
   * @returns The new deposited USDS amount.
   */
  apply(change: StabilityDepositChange<Decimalish> | undefined): Decimal {
    if (!change) {
      return this.currentUSDS;
    }

    if (change.withdrawUSDS !== undefined) {
      return change.withdrawAllUSDS || this.currentUSDS.lte(change.withdrawUSDS)
        ? Decimal.ZERO
        : this.currentUSDS.sub(change.withdrawUSDS);
    } else {
      return this.currentUSDS.add(change.depositUSDS);
    }
  }
}
