import { useStakingView } from "./context/StakingViewContext";
import { ReadOnlyStake } from "./ReadOnlyStake";
import { StakingManager } from "./StakingManager";
import { NoStake } from "./NoStake";
import StakingProvider from "./context/StakingContext";

export const Staking: React.FC = () => {
  const { view } = useStakingView();
  const renderView = () => {
    switch (view) {
      case "ACTIVE":
        return <ReadOnlyStake />;

      case "ADJUSTING":
        return <StakingManager />;

      case "NONE":
        return <NoStake />;
    }
  };
  return <StakingProvider>{renderView()}</StakingProvider>;
};
