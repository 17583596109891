import { useEffect, useState } from "react";
import { BigNumber, utils } from "ethers";
import { ChainId } from "../config/web3/chains";
import { useWeb3React } from "@web3-react/core";
import { useContract } from "./useContract";
import { getStakedLPTVLCheckerAddr } from "../config/addresses";
import useChain from "./useChain";
import useLpAddress from "./useLpAddress";
import { useLiquity } from "./LiquityContext";
import { fetchSABLEPrice } from "../components/Stability/context/fetchSABLEPrice";

function useStakedLPUSDValueChecker() {
  const { chainId } = useWeb3React();
  const chain = useChain();
  const { liquity } = useLiquity();
  const connection = liquity.connection;
  const [stakedLPTVLInUSD, setStakedLPTVLInUSD] = useState<number>(0);
  const lpAddress = useLpAddress();
  const sableTokenContract = useContract<any>(lpAddress, [
    "function balanceOf(address owner) view returns (uint256)"
  ]);
  const rewardsCheckerContract = useContract<any>(getStakedLPTVLCheckerAddr(chainId as ChainId), [
    "function getStakedLPTVL() returns (uint)"
  ]);

  useEffect(() => {
    const fetchExchangeData = async () => {
      try {
        if (chain.isStakeLPSupported) {
          if (rewardsCheckerContract[0]) {
            const userReward = await rewardsCheckerContract[0].callStatic.getStakedLPTVL();
            const stakedLPTVLInUSD = parseFloat(utils.formatUnits(userReward, 0));
            setStakedLPTVLInUSD(stakedLPTVLInUSD);
          }
        } else {
          if (sableTokenContract[0]) {
            const totalSABLE = (await sableTokenContract[0].callStatic.balanceOf(
              connection.addresses.sableStaking
            )) as BigNumber;
            const { sablePriceUSD } = await fetchSABLEPrice();
            const stakeSableInUSD =
              Number(utils.formatEther(totalSABLE)) * Number(sablePriceUSD.toString());
            setStakedLPTVLInUSD(stakeSableInUSD);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchExchangeData();

    const interval = setInterval(fetchExchangeData, 60000); // Fetch data every 1 minute

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, [
    chain.isStakeLPSupported,
    connection.addresses.sableStaking,
    rewardsCheckerContract,
    sableTokenContract
  ]);

  return { stakedLPTVLInUSD }; // Return the reward state
}

export default useStakedLPUSDValueChecker;
