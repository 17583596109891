import React, { useCallback } from "react";
import { Card, Heading, Box, Button, Flex, Link } from "theme-ui";
import { CollateralSurplusAction } from "../CollateralSurplusAction";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { useTroveView } from "./context/TroveViewContext";
import { InfoMessage } from "../InfoMessage";

const select = ({ collateralSurplusBalance }: LiquityStoreState) => ({
  hasSurplusCollateral: !collateralSurplusBalance.isZero
});

export const LiquidatedTrove: React.FC = () => {
  const { hasSurplusCollateral } = useLiquitySelector(select);
  const { dispatchEvent } = useTroveView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>Trove</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="Your Trove has been liquidated.">
          {hasSurplusCollateral ? (
            "Please reclaim your remaining collateral before opening a new Trove."
          ) : (
            <>
              You can borrow USDS against BNB collateral by opening a Trove.
              <Flex sx={{ justifyContent: "flex-start", alignItems: "center", gap: "0.25rem" }}>
                Learn more:
                <Link
                  sx={{ fontSize: 2 }}
                  href="https://sable-finance.gitbook.io/docs/concept-overview/usds-borrowing"
                  target="_blank"
                >
                  USDS borrowing
                </Link>
              </Flex>
            </>
          )}
        </InfoMessage>

        <Flex variant="layout.actions">
          {hasSurplusCollateral && <CollateralSurplusAction />}
          {!hasSurplusCollateral && <Button onClick={handleOpenTrove}>Open Trove</Button>}
        </Flex>
      </Box>
    </Card>
  );
};
