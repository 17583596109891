import React, { useCallback, useEffect, useState } from "react";
import { Flex, Button, Box, Card, Heading, Spinner } from "theme-ui";
import {
  LiquityStoreState,
  Decimal,
  Trove,
} from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { useStableTroveChange } from "../../hooks/useStableTroveChange";
import { ActionDescription } from "../ActionDescription";
import { useMyTransactionState } from "../Transaction";
import { TroveAction } from "./TroveAction";
import { useTroveView } from "./context/TroveViewContext";
import { COIN } from "../../strings";
import { Icon } from "../Icon";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlay } from "../LoadingOverlay";
import { CollateralRatio } from "./CollateralRatio";
import { EditableRow, StaticRow } from "./Editor";
import { ExpensiveTroveChangeWarning, GasEstimationState } from "./ExpensiveTroveChangeWarning";
import {
  selectForTroveChangeValidation,
  systemStateForTroveChangeValidation,
  validateTroveChange
} from "./validation/validateTroveChange";
import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import { BORROWING_FEE_TOOLTIP, LIQUIDATION_RESERVE_TOOLTIP } from "./constants";
import useChain from "../../hooks/useChain";

const selector = (state: LiquityStoreState) => {
  const { fees, price, accountBalance } = state;
  return {
    fees,
    price,
    accountBalance,
    validationContext: selectForTroveChangeValidation(state)
  };
};

export const selectSystemState = (state: BlockPolledLiquityStoreState) => {
  const { usdsGasCompensation, minNetDebt, borrowingFeeFloor } = state;
  return {
    usdsGasCompensation,
    minNetDebt,
    borrowingFeeFloor,
    systemState: systemStateForTroveChangeValidation(state)
  };
};

const EMPTY_TROVE = new Trove(Decimal.ZERO, Decimal.ZERO);
const TRANSACTION_ID = "trove-creation";
const GAS_ROOM_BNB = Decimal.from(0.1);

const MAX_ORACLE_RATE = Decimal.from(0.0025);

export const Opening: React.FC = () => {
  const { dispatchEvent } = useTroveView();
  const chain = useChain();

  const { fees, price, accountBalance, validationContext } = useLiquitySelector(selector);
  const { usdsGasCompensation, minNetDebt, systemState, borrowingFeeFloor } =
    useLiquitySelector(selectSystemState);

  // params changes
  console.log("usdsGasCompensation: ", usdsGasCompensation.toString());

  const USDS_LIQUIDATION_RESERVE = usdsGasCompensation;
  const USDS_MINIMUM_NET_DEBT = minNetDebt;

  const borrowingRate = fees.borrowingRate(borrowingFeeFloor);
  const editingState = useState<string>();

  const currencySymbol = chain.nativeCurrency.symbol;

  const [collateral, setCollateral] = useState<Decimal>(Decimal.ZERO);
  const [borrowAmount, setBorrowAmount] = useState<Decimal>(Decimal.ZERO);

  const maxBorrowingRate = borrowingRate.add(0.005);

  const fee = borrowAmount.mul(borrowingRate);
  const maxBorrowingFee = borrowAmount.mul(MAX_ORACLE_RATE);
  const totalDebt = borrowAmount.add(USDS_LIQUIDATION_RESERVE).add(fee);
  const maxTotalDebt = borrowAmount.add(USDS_LIQUIDATION_RESERVE).add(fee.add(maxBorrowingFee));
  const isDirty = !collateral.isZero || !borrowAmount.isZero;
  const trove = isDirty ? new Trove(collateral, totalDebt) : EMPTY_TROVE;
  const maxCollateral = accountBalance.gt(GAS_ROOM_BNB)
    ? accountBalance.sub(GAS_ROOM_BNB)
    : Decimal.ZERO;
  const collateralMaxedOut = collateral.eq(maxCollateral);
  const collateralRatio =
    !collateral.isZero && !borrowAmount.isZero ? trove.collateralRatio(price) : undefined;

  const [troveChange, description] = validateTroveChange(
    EMPTY_TROVE,
    trove,
    borrowingRate,
    validationContext,
    systemState
  );

  const stableTroveChange = useStableTroveChange(troveChange);
  const [gasEstimationState, setGasEstimationState] = useState<GasEstimationState>({ type: "idle" });

  const transactionState = useMyTransactionState(TRANSACTION_ID);
  const isTransactionPending =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const handleCancelPressed = useCallback(() => {
    dispatchEvent("CANCEL_ADJUST_TROVE_PRESSED");
  }, [dispatchEvent]);

  const reset = useCallback(() => {
    setCollateral(Decimal.ZERO);
    setBorrowAmount(Decimal.ZERO);
  }, []);

  useEffect(() => {
    if (!collateral.isZero && borrowAmount.isZero) {
      setBorrowAmount(USDS_MINIMUM_NET_DEBT);
    }
  }, [collateral, borrowAmount, USDS_MINIMUM_NET_DEBT]);

  return (
    <Card>
      <Heading>
        Trove
        {isDirty && !isTransactionPending && (
          <Button variant="titleIcon" sx={{ ":enabled:hover": { color: "danger" } }} onClick={reset}>
            <Icon name="history" size="lg" />
          </Button>
        )}
      </Heading>

      <Box sx={{ p: [2, 3] }}>
        <EditableRow
          label="Collateral"
          trailingLabel={
            <BalanceLabel balance={accountBalance.toString()} tokenSymbol={currencySymbol} />
          }
          inputId="trove-collateral"
          amount={collateral.prettify(4)}
          maxAmount={maxCollateral.toString()}
          maxedOut={collateralMaxedOut}
          editingState={editingState}
          unit={currencySymbol}
          editedAmount={collateral.toString(4)}
          setEditedAmount={(amount: string) => setCollateral(Decimal.from(amount))}
        />

        <EditableRow
          label="Borrow"
          inputId="trove-borrow-amount"
          amount={borrowAmount.prettify()}
          unit={COIN}
          editingState={editingState}
          editedAmount={borrowAmount.toString(2)}
          setEditedAmount={(amount: string) => setBorrowAmount(Decimal.from(amount))}
        />

        <StaticRow
          label="Liquidation Reserve"
          inputId="trove-liquidation-reserve"
          amount={`${USDS_LIQUIDATION_RESERVE.toString(0)}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "200px" }}>
                  {LIQUIDATION_RESERVE_TOOLTIP}
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Borrowing Fee"
          inputId="trove-borrowing-fee"
          amount={`Max. ${(fee.add(maxBorrowingFee)).prettify(2)}`}
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  {BORROWING_FEE_TOOLTIP}
                </Card>
              }
            />
          }
        />

        <StaticRow
          label="Total debt"
          inputId="trove-total-debt"
          amount={
            totalDebt.eq(maxTotalDebt)
              ? totalDebt.prettify(2)
              : `${totalDebt.prettify(2)} - ${maxTotalDebt.prettify(2)}`
          }
          unit={COIN}
          infoIcon={
            <InfoIcon
              tooltip={
                <Card variant="tooltip" sx={{ width: "240px" }}>
                  The total amount of USDS your Trove will hold.{" "}
                  {isDirty && (
                    <>
                      You will need to repay{" "}
                      up to{" "}
                      {maxTotalDebt.sub(Decimal.from(USDS_LIQUIDATION_RESERVE)).prettify(2)} USDS to
                      reclaim your collateral ({USDS_LIQUIDATION_RESERVE.toString()} USDS Liquidation
                      Reserve excluded).
                    </>
                  )}
                </Card>
              }
            />
          }
        />

        <CollateralRatio value={collateralRatio} />

        {description ?? (
          <ActionDescription>
            Start by entering the amount of {currencySymbol} you'd like to deposit as collateral.
          </ActionDescription>
        )}

        <ExpensiveTroveChangeWarning
          troveChange={stableTroveChange}
          maxBorrowingRate={maxBorrowingRate}
          borrowingFeeDecayToleranceMinutes={60}
          gasEstimationState={gasEstimationState}
          setGasEstimationState={setGasEstimationState}
        />

        <Flex variant="layout.actions">
          <Button variant="cancel" onClick={handleCancelPressed}>
            Cancel
          </Button>

          {gasEstimationState.type === "inProgress" ? (
            <Button disabled>
              <Spinner size={24} sx={{ color: "#B3B3B3" }} />
            </Button>
          ) : stableTroveChange ? (
            <TroveAction
              transactionId={TRANSACTION_ID}
              change={stableTroveChange}
              maxBorrowingRate={maxBorrowingRate}
              borrowingFeeDecayToleranceMinutes={60}
            >
              Confirm
            </TroveAction>
          ) : (
            <Button disabled>Confirm</Button>
          )}
        </Flex>
      </Box>
      {isTransactionPending && <LoadingOverlay />}
    </Card>
  );
};
