import { useEffect, useState } from "react";
import { _InternalEthersLiquityConnection } from "@liquity/lib-ethers/dist/src/EthersLiquityConnection";
import { useLiquity } from "./LiquityContext";
import useChain from "./useChain";

const useLpAddress = (): string => {
  const chain = useChain();
  const { liquity } = useLiquity();

  const [lpAddress, setLpAddress] = useState("");
  const connection = liquity.connection as _InternalEthersLiquityConnection;
  const { sableStaking: sableStakingContract } = connection._contracts;

  useEffect(() => {
    (async () => {
      const _lpAddress = chain.isStakeLPSupported
        ? await sableStakingContract.sableLPToken()
        : connection.addresses.sableToken;

      setLpAddress(_lpAddress);
    })();
  }, [chain.isStakeLPSupported, connection.addresses.sableToken, lpAddress, sableStakingContract]);

  return lpAddress;
};

export default useLpAddress;
