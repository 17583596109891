import { Flex, Box } from "theme-ui";
import { Link } from "./Link";

export const Nav: React.FC = () => {
  return (
    <Box
      as="nav"
      sx={{
        display: "flex",
        "@media screen and (max-width: 70em)": {
          display: "none"
        },

        alignItems: "center",
        flex: 1
      }}
    >
      <Flex>
        <Link to="/">Dashboard</Link>
      </Flex>
      <Flex sx={{ justifyContent: "flex-end", mr: 3, flex: 1 }}>
        <Link sx={{ fontSize: 1 }} to="/risky-troves">
          Risky Troves
        </Link>
        <Link sx={{ fontSize: 1 }} to="/redemption">
          Redemption
        </Link>
      </Flex>
    </Box>
  );
};
