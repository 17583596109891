import { useState } from "react";
interface Props<T> {
  key: string;
  initialValue: T;
}
// reference: https://usehooks.com/useLocalStorage/
function useLocalStorage<T>({
  key,
  initialValue
}: Props<T>): [T, (value: T | ((value: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValueToLocalStorage = (value: T | ((value: T) => T)) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      setStoredValue(valueToStore);
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValueToLocalStorage];
}

export default useLocalStorage;
