// export const BASE_URL_PYTH_MAINNET = "https://xc-mainnet.pyth.network";
// export const BASE_URL_PYTH_TESTNET = "https://xc-testnet.pyth.network";
export const BASE_URL_PYTH_MAINNET = "https://hermes.pyth.network";
export const BASE_URL_PYTH_TESTNET = "https://hermes.pyth.network";

export enum ChainId {
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  BASE = 8453,
  BASE_TESTNET = 84531
}

export const PYTH_IDS: {
  [id in ChainId]: string;
} = {
  [ChainId.BSC_MAINNET]: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
  [ChainId.BSC_TESTNET]: "0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f",
  // [ChainId.BSC_TESTNET]: "0xecf553770d9b10965f8fb64771e93f5690a182edc32be4a3236e0caaa6e0581a",
  [ChainId.BASE]: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace",
  [ChainId.BASE_TESTNET]: "0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace"
  // [ChainId.BASE_TESTNET]: "0xca80ba6dc32e08d06f1aa886011eed1d77c77be9eb761cc10d72b7d0a2fd57a6"
};

export const getBaseUrlPyth = (chainId: ChainId): string => {
  switch (Number(chainId)) {
    case ChainId.BSC_TESTNET:
    case ChainId.BASE_TESTNET:
      return BASE_URL_PYTH_TESTNET;
    case ChainId.BSC_MAINNET:
    case ChainId.BASE:
    default:
      return BASE_URL_PYTH_MAINNET;
  }
};
