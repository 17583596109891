import React from "react";
import { Button } from "theme-ui";
import pancakeswapLogo from "../../assets/icons/pancakeswap-cake.svg";
import useChain from "../../hooks/useChain";
import { ChainId } from "../../config/web3/chains";

function GetSableBnbLpButton() {
  const chain = useChain();
  const lpUrl: { [id in ChainId]: string } = {
    [ChainId.BSC_MAINNET]:
      "https://pancakeswap.finance/add/0x1eE098cBaF1f846d5Df1993f7e2d10AFb35A878d/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c?chain=bsc",
    [ChainId.BSC_TESTNET]: "",
    [ChainId.BASE]: "",
    [ChainId.BASE_TESTNET]: ""
  };
  const showPancakeIcon = chain.id === 97 || chain.id === 56;
  const lpSymbol = chain.isStakeLPSupported ? `SABLE-${chain.nativeCurrency.symbol}` : "SABLE";

  return (
    <Button
      onClick={() => window.open(lpUrl[chain.id])}
      marginRight="auto"
      sx={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div>Get {lpSymbol}</div>
      {showPancakeIcon && (
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          ({" "}
          <img
            style={{
              margin: "0px 5px"
            }}
            width={20}
            alt="pancake"
            src={pancakeswapLogo}
          />
          V2 Liquidty)
        </div>
      )}
    </Button>
  );
}

export default GetSableBnbLpButton;
