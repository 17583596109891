import React from "react";

type Props = {
  balance: string;
  tokenSymbol: string;
};
/**
 *
 * This component is for trailing label in DisabledEditableRow or EditableRow component
 */
function BalanceLabel({ balance, tokenSymbol }: Props) {
  return (
    <>
      Balance: {Number(balance).toFixed(4)} {tokenSymbol}
    </>
  );
}

export default BalanceLabel;
