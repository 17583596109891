import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStabilityView } from "./context/StabilityViewContext";
import { Yield } from "./Yield";
import useChain from "../../hooks/useChain";

export const NoDeposit: React.FC = props => {
  const { dispatchEvent } = useStabilityView();
  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  return (
    <Card>
      <Heading>Stability Pool</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You have no USDS in the Stability Pool.">
          You can earn {currencySymbol} and SABLE rewards by depositing USDS.
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", gap: "0.25rem" }}>
            Learn more:
            <Link
              sx={{ fontSize: 2 }}
              href="https://sable-finance.gitbook.io/docs/concept-overview/stability-pool"
              target="_blank"
            >
              Stability pool
            </Link>
          </Flex>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Flex sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center" }}>
            <Yield />
          </Flex>
          <Button onClick={handleOpenTrove}>Deposit</Button>
        </Flex>
      </Box>
    </Card>
  );
};
