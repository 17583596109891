import {
  Decimal,
  Trove,
  TroveAdjustmentParams,
  TroveChange,
  Percent,
  LiquityStoreState,
  TroveClosureParams,
  TroveCreationParams
} from "@liquity/lib-base";

import { COIN } from "../../../strings";

import { ActionDescription, Amount } from "../../ActionDescription";
import { ErrorDescription } from "../../ErrorDescription";
import { BlockPolledLiquityStoreState } from "@liquity/lib-ethers";
import useChain from "../../../hooks/useChain";

type TroveAdjustmentDescriptionParams = {
  params: TroveAdjustmentParams<Decimal>;
};

const TroveChangeDescription: React.FC<TroveAdjustmentDescriptionParams> = ({ params }) => {
  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;

  return (
    <ActionDescription>
      {params.depositCollateral && params.borrowUSDS ? (
        <>
          You will deposit{" "}
          <Amount>
            {params.depositCollateral.prettify()} {currencySymbol}
          </Amount>{" "}
          and receive{" "}
          <Amount>
            {params.borrowUSDS.prettify()} {COIN}
          </Amount>
        </>
      ) : params.repayUSDS && params.withdrawCollateral ? (
        <>
          You will pay{" "}
          <Amount>
            {params.repayUSDS.prettify()} {COIN}
          </Amount>{" "}
          and receive{" "}
          <Amount>
            {params.withdrawCollateral.prettify()} {currencySymbol}
          </Amount>
        </>
      ) : params.depositCollateral && params.repayUSDS ? (
        <>
          You will deposit{" "}
          <Amount>
            {params.depositCollateral.prettify()} {currencySymbol}
          </Amount>{" "}
          and pay{" "}
          <Amount>
            {params.repayUSDS.prettify()} {COIN}
          </Amount>
        </>
      ) : params.borrowUSDS && params.withdrawCollateral ? (
        <>
          You will receive{" "}
          <Amount>
            {params.withdrawCollateral.prettify()} {currencySymbol}
          </Amount>{" "}
          and{" "}
          <Amount>
            {params.borrowUSDS.prettify()} {COIN}
          </Amount>
        </>
      ) : params.depositCollateral ? (
        <>
          You will deposit{" "}
          <Amount>
            {params.depositCollateral.prettify()} {currencySymbol}
          </Amount>
        </>
      ) : params.withdrawCollateral ? (
        <>
          You will receive{" "}
          <Amount>
            {params.withdrawCollateral.prettify()} {currencySymbol}
          </Amount>
        </>
      ) : params.borrowUSDS ? (
        <>
          You will receive{" "}
          <Amount>
            {params.borrowUSDS.prettify()} {COIN}
          </Amount>
        </>
      ) : (
        <>
          You will pay{" "}
          <Amount>
            {params.repayUSDS.prettify()} {COIN}
          </Amount>
        </>
      )}
      .
    </ActionDescription>
  );
};

export const selectForTroveChangeValidation = ({
  price,
  total,
  accountBalance,
  usdsBalance,
  numberOfTroves
}: LiquityStoreState) => ({ price, total, accountBalance, usdsBalance, numberOfTroves });

export const systemStateForTroveChangeValidation = ({
  mcrNumber,
  minNetDebt,
  ccrNumber,
  usdsGasCompensation
}: BlockPolledLiquityStoreState) => ({
  mcrNumber,
  minNetDebt,
  ccrNumber,
  usdsGasCompensation
});

type TroveChangeValidationSelectedState = ReturnType<typeof selectForTroveChangeValidation>;
type TroveChangeValidationSystemState = ReturnType<typeof systemStateForTroveChangeValidation>;

interface TroveChangeValidationContext
  extends TroveChangeValidationSelectedState,
    TroveChangeValidationSystemState {
  originalTrove: Trove;
  resultingTrove: Trove;
  recoveryMode: boolean;
  wouldTriggerRecoveryMode: boolean;
}

export const validateTroveChange = (
  originalTrove: Trove,
  adjustedTrove: Trove,
  borrowingRate: Decimal,
  selectedState: TroveChangeValidationSelectedState,
  systemState: TroveChangeValidationSystemState
): [
  validChange: Exclude<TroveChange<Decimal>, { type: "invalidCreation" }> | undefined,
  description: JSX.Element | undefined
] => {
  const { minNetDebt, usdsGasCompensation, ccrNumber } = systemState;
  const USDS_MINIMUM_NET_DEBT = minNetDebt;
  const USDS_LIQUIDATION_RESERVE = usdsGasCompensation;
  const USDS_MINIMUM_DEBT = USDS_LIQUIDATION_RESERVE.add(USDS_MINIMUM_NET_DEBT);
  const { total, price } = selectedState;
  const change = originalTrove.whatChanged(adjustedTrove, borrowingRate, USDS_LIQUIDATION_RESERVE);

  if (!change) {
    return [undefined, undefined];
  }

  // Reapply change to get the exact state the Trove will end up in (which could be slightly
  // different from `edited` due to imprecision).
  const resultingTrove = originalTrove.apply(change, borrowingRate, USDS_LIQUIDATION_RESERVE);
  const recoveryMode = total.collateralRatioIsBelowCritical(price, ccrNumber);
  const wouldTriggerRecoveryMode = total
    .subtract(originalTrove)
    .add(resultingTrove)
    .collateralRatioIsBelowCritical(price, ccrNumber);

  const context: TroveChangeValidationContext = {
    ...selectedState,
    originalTrove,
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode,
    ...systemState
  };

  if (change.type === "invalidCreation") {
    // Trying to create a Trove with negative net debt
    return [
      undefined,
      <ErrorDescription>
        Total debt must be at least{" "}
        <Amount>
          {USDS_MINIMUM_DEBT.toString()} {COIN}
        </Amount>
        .
      </ErrorDescription>
    ];
  }

  const errorDescription =
    change.type === "creation"
      ? ValidateTroveCreation(change.params, context)
      : change.type === "closure"
      ? ValidateTroveClosure(change.params, context)
      : ValidateTroveAdjustment(change.params, context);

  if (errorDescription) {
    return [undefined, errorDescription];
  }

  return [change, <TroveChangeDescription params={change.params} />];
};

const ValidateTroveCreation = (
  { depositCollateral, borrowUSDS }: TroveCreationParams<Decimal>,
  {
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode,
    accountBalance,
    price,
    ...systemState
  }: TroveChangeValidationContext
): JSX.Element | null => {
  const chain = useChain();
  const { minNetDebt, ccrNumber, mcrNumber } = systemState;
  const USDS_MINIMUM_NET_DEBT = minNetDebt;
  const MINIMUM_COLLATERAL_RATIO = mcrNumber;
  const CRITICAL_COLLATERAL_RATIO = ccrNumber;
  const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);
  const ccrPercent = new Percent(CRITICAL_COLLATERAL_RATIO).toString(0);

  if (borrowUSDS.lt(USDS_MINIMUM_NET_DEBT)) {
    return (
      <ErrorDescription>
        You must borrow at least{" "}
        <Amount>
          {USDS_MINIMUM_NET_DEBT.toString()} {COIN}
        </Amount>
        .
      </ErrorDescription>
    );
  }

  if (recoveryMode) {
    if (!resultingTrove.isOpenableInRecoveryMode(price, ccrNumber)) {
      return (
        <ErrorDescription>
          You're not allowed to open a Trove with less than <Amount>{ccrPercent}</Amount> Collateral
          Ratio during recovery mode. Please increase your Trove's Collateral Ratio.
        </ErrorDescription>
      );
    }
  } else {
    if (
      resultingTrove.collateralRatioIsBelowMinimum(price, Decimal.from(MINIMUM_COLLATERAL_RATIO))
    ) {
      return (
        <ErrorDescription>
          Collateral ratio must be at least <Amount>{mcrPercent}</Amount>.
        </ErrorDescription>
      );
    }

    if (wouldTriggerRecoveryMode) {
      return (
        <ErrorDescription>
          You're not allowed to open a Trove that would cause the Total Collateral Ratio to fall
          below <Amount>{ccrPercent}</Amount>. Please increase your Trove's Collateral Ratio.
        </ErrorDescription>
      );
    }
  }

  if (depositCollateral.gt(accountBalance)) {
    return (
      <ErrorDescription>
        The amount you're trying to deposit exceeds your balance by{" "}
        <Amount>
          {depositCollateral.sub(accountBalance).prettify()} {chain.nativeCurrency.symbol}
        </Amount>
        .
      </ErrorDescription>
    );
  }

  return null;
};

const ValidateTroveAdjustment = (
  { depositCollateral, withdrawCollateral, borrowUSDS, repayUSDS }: TroveAdjustmentParams<Decimal>,
  {
    originalTrove,
    resultingTrove,
    recoveryMode,
    wouldTriggerRecoveryMode,
    price,
    accountBalance,
    usdsBalance,
    ...systemState
  }: TroveChangeValidationContext
): JSX.Element | null => {
  const chain = useChain();
  const { minNetDebt, ccrNumber, mcrNumber, usdsGasCompensation } = systemState;
  const USDS_MINIMUM_NET_DEBT = minNetDebt;
  const USDS_LIQUIDATION_RESERVE = usdsGasCompensation;
  const USDS_MINIMUM_DEBT = USDS_LIQUIDATION_RESERVE.add(USDS_MINIMUM_NET_DEBT);
  const MINIMUM_COLLATERAL_RATIO = mcrNumber;
  const CRITICAL_COLLATERAL_RATIO = ccrNumber;
  const mcrPercent = new Percent(MINIMUM_COLLATERAL_RATIO).toString(0);
  const ccrPercent = new Percent(CRITICAL_COLLATERAL_RATIO).toString(0);
  if (recoveryMode) {
    if (withdrawCollateral) {
      return (
        <ErrorDescription>
          You're not allowed to withdraw collateral during recovery mode.
        </ErrorDescription>
      );
    }

    if (borrowUSDS) {
      if (resultingTrove.collateralRatioIsBelowCritical(price, ccrNumber)) {
        return (
          <ErrorDescription>
            Your collateral ratio must be at least <Amount>{ccrPercent}</Amount> to borrow during
            recovery mode. Please improve your collateral ratio.
          </ErrorDescription>
        );
      }

      if (resultingTrove.collateralRatio(price).lt(originalTrove.collateralRatio(price))) {
        return (
          <ErrorDescription>
            You're not allowed to decrease your collateral ratio during recovery mode.
          </ErrorDescription>
        );
      }
    }
  } else {
    if (resultingTrove.collateralRatioIsBelowMinimum(price, MINIMUM_COLLATERAL_RATIO)) {
      return (
        <ErrorDescription>
          Collateral ratio must be at least <Amount>{mcrPercent}</Amount>.
        </ErrorDescription>
      );
    }

    if (wouldTriggerRecoveryMode) {
      return (
        <ErrorDescription>
          The adjustment you're trying to make would cause the Total Collateral Ratio to fall below{" "}
          <Amount>{ccrPercent}</Amount>. Please increase your Trove's Collateral Ratio.
        </ErrorDescription>
      );
    }
  }

  if (repayUSDS) {
    if (resultingTrove.debt.lt(USDS_MINIMUM_DEBT)) {
      return (
        <ErrorDescription>
          Total debt must be at least{" "}
          <Amount>
            {USDS_MINIMUM_DEBT.toString()} {COIN}
          </Amount>
          .
        </ErrorDescription>
      );
    }

    if (repayUSDS.gt(usdsBalance)) {
      return (
        <ErrorDescription>
          The amount you're trying to repay exceeds your balance by{" "}
          <Amount>
            {repayUSDS.sub(usdsBalance).prettify()} {COIN}
          </Amount>
          .
        </ErrorDescription>
      );
    }
  }

  if (depositCollateral?.gt(accountBalance)) {
    return (
      <ErrorDescription>
        The amount you're trying to deposit exceeds your balance by{" "}
        <Amount>
          {depositCollateral.sub(accountBalance).prettify()} {chain.nativeCurrency.symbol}
        </Amount>
        .
      </ErrorDescription>
    );
  }

  return null;
};

const ValidateTroveClosure = (
  { repayUSDS }: TroveClosureParams<Decimal>,
  {
    recoveryMode,
    wouldTriggerRecoveryMode,
    numberOfTroves,
    usdsBalance,
    ...systemState
  }: TroveChangeValidationContext
): JSX.Element | null => {
  const { ccrNumber } = systemState;
  const CRITICAL_COLLATERAL_RATIO = ccrNumber;
  const ccrPercent = new Percent(CRITICAL_COLLATERAL_RATIO).toString(0);

  if (numberOfTroves === 1) {
    return (
      <ErrorDescription>
        You're not allowed to close your Trove when there are no other Troves in the system.
      </ErrorDescription>
    );
  }

  if (recoveryMode) {
    return (
      <ErrorDescription>
        You're not allowed to close your Trove during recovery mode.
      </ErrorDescription>
    );
  }

  if (repayUSDS?.gt(usdsBalance)) {
    return (
      <ErrorDescription>
        You need{" "}
        <Amount>
          {repayUSDS.sub(usdsBalance).prettify()} {COIN}
        </Amount>{" "}
        more to close your Trove.
      </ErrorDescription>
    );
  }

  if (wouldTriggerRecoveryMode) {
    return (
      <ErrorDescription>
        You're not allowed to close a Trove if it would cause the Total Collateralization Ratio to
        fall below <Amount>{ccrPercent}</Amount>. Please wait until the Total Collateral Ratio
        increases.
      </ErrorDescription>
    );
  }

  return null;
};
