import React from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { InfoIcon } from "../InfoIcon";
import { Badge } from "../Badge";
import { useStakingContext } from "./context/StakingContext";


export const Yield: React.FC = () => {
  const {
    pancakeSwapPool2Data: { lpfeeApr }
  } = useStakingContext();
  const displayApr = lpfeeApr?.toFixed(2) || "-"
  return (
    <Badge>
      <Text>LP fee APR {displayApr}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" >
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the annualized trading fees return of your SABLE-BNB LP on PancakeSwap. This APR estimate does not include borrowing and redemption fees you receive by staking.
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
