import { Heading, Box, Card, Flex, Button } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN } from "../../strings";

import { DisabledEditableRow, StaticRow } from "../Trove/Editor";
import { LoadingOverlay } from "../LoadingOverlay";
import { Icon } from "../Icon";

import { useStakingView } from "./context/StakingViewContext";
import { StakingGainsAction } from "./StakingGainsAction";
import GetSableBnbLpButton from "./GetSableBnbLpButton";
import SableBnbLpBalanceLabel from "./SableBnbLpBalanceLabel";
import { useStakingContext } from "./context/StakingContext";
import useChain from "../../hooks/useChain";

const select = ({ sableStake, totalStakedSABLE }: LiquityStoreState) => ({
  sableStake,
  totalStakedSABLE
});

export const ReadOnlyStake: React.FC = () => {
  const { changePending, dispatch } = useStakingView();
  const { sableStake, totalStakedSABLE } = useLiquitySelector(select);

  const poolShare = sableStake.stakedSABLE.mulDiv(100, totalStakedSABLE);
  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;
  const lpSymbol = chain.isStakeLPSupported ? `SABLE-${chain.nativeCurrency.symbol}` : "SABLE";
  const {
    pancakeSwapPool2Data: { lpfeeApr }
  } = useStakingContext();
  return (
    <Card>
      <Heading>Staking</Heading>

      <Box sx={{ p: [2, 3] }}>
        <DisabledEditableRow
          label="Stake"
          inputId="stake-sable"
          amount={sableStake.stakedSABLE.prettify()}
          unit={lpSymbol}
          trailingLabel={<SableBnbLpBalanceLabel />}
        />

        <StaticRow
          label="Pool share"
          inputId="stake-share"
          amount={poolShare.prettify(4)}
          unit="%"
        />

        <StaticRow
          label="Redemption gain"
          inputId="stake-gain-eth"
          amount={sableStake.collateralGain.prettify(4)}
          color={sableStake.collateralGain.nonZero && "success"}
          unit={currencySymbol}
        />

        <StaticRow
          label="Issuance gain"
          inputId="stake-gain-usds"
          amount={sableStake.usdsGain.prettify()}
          color={sableStake.usdsGain.nonZero && "success"}
          unit={COIN}
        />
        {chain.isStakeLPSupported && (
          <StaticRow
            label="LP fee APR"
            inputId="lp-fee-apr"
            amount={lpfeeApr?.toFixed(2) || "-"}
            color="success"
            unit="%"
          />
        )}

        <Flex variant="layout.actions">
          <GetSableBnbLpButton />
          <Button variant="cancel" onClick={() => dispatch({ type: "startAdjusting" })}>
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>

          <StakingGainsAction />
        </Flex>
      </Box>

      {changePending && <LoadingOverlay />}
    </Card>
  );
};
