import { Theme, ThemeUIStyleObject } from "theme-ui";

const baseColors = {
  blue: "#1542cd",
  purple: "#745ddf",
  cyan: "#2eb6ea",
  green: "#28c081",
  yellow: "#fd9d28",
  red: "#dc2c10",
  lightRed: "#ff755f",
  lightYellow: "#F9E39D",
  yellow_100: "#FCF1CE",
  yellow_200: "#F0B90B",
  yellow_300: "#D8A60A",
  yellow_400: "#C09409",
  lightWhite: "#ffffff80",
  lightGrey: "#E5E5E5",
  grey_100: "#B3B3B3",
  black: "#000000",
  white: "#ffffff",
  lightBlack: "#666666",
  red_100: "#B00020",
  brown: "#804C3B"
};

const colors = {
  primary: baseColors.brown,
  secondary: baseColors.purple,
  accent: baseColors.cyan,

  success: baseColors.green,
  warning: baseColors.yellow,
  danger: baseColors.red,
  dangerHover: baseColors.lightRed,
  info: baseColors.blue,
  invalid: "pink",

  text: "#293147",
  background: "white",
  muted: "#eaebed"
};

const buttonBase: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "Avenir",
  letterSpacing: 1.25,
  ":enabled": { cursor: "pointer" }
};

const button: ThemeUIStyleObject = {
  ...buttonBase,

  px: "32px",
  py: "12px",

  color: "white",
  border: 1,

  fontWeight: "medium",
  textTransform: "capitalize",

  ":disabled": {
    opacity: 0.5
  }
};

const buttonOutline = (color: string, hoverColor: string): ThemeUIStyleObject => ({
  color,
  borderColor: color,
  background: "none",
  borderWidth: 2,
  borderRadius: 10
});

const iconButton: ThemeUIStyleObject = {
  ...buttonBase,

  padding: 0,
  width: "40px",
  height: "40px",

  background: "none",

  ":disabled": {
    color: "text",
    opacity: 0.25
  }
};

const cardHeadingFontSize = 24;

const cardGapX = [0, 3, 4];
const cardGapY = [3, 3, 4];

const card: ThemeUIStyleObject = {
  position: "relative",
  mt: cardGapY,
  // border: 1,
  boxShadow: [1, null, 2]
};

const infoCard: ThemeUIStyleObject = {
  ...card,

  padding: 3,

  // borderColor: "rgba(122,199,240,0.4)",
  background: "linear-gradient(180deg, #FBEAB6 0%, rgba(251, 234, 182, 0) 100%)",

  h2: {
    mb: 2,
    fontSize: cardHeadingFontSize
  }
};

const formBase: ThemeUIStyleObject = {
  display: "block",
  width: "auto",
  flexShrink: 0,
  padding: 2,
  fontSize: 3
};

const formCell: ThemeUIStyleObject = {
  ...formBase,

  bg: "background",
  border: 1,
  borderColor: "muted",
  borderRadius: 0,
  boxShadow: [1, 2]
};

const overlay: ThemeUIStyleObject = {
  position: "absolute",

  left: 0,
  top: 0,
  width: "100%",
  height: "100%"
};

const modalOverlay: ThemeUIStyleObject = {
  position: "fixed",

  left: 0,
  top: 0,
  width: "100vw",
  height: "100vh"
};

const headerGradient: ThemeUIStyleObject = {
  background: "#EAEAE5"
};

const theme: Theme = {
  breakpoints: ["48em", "52em", "64em"],

  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],

  fonts: {
    body: ["Avenir", "sans-serif"].join(", "),
    heading: "Avenir",
    monospace: "Menlo, monospace"
  },

  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],

  fontWeights: {
    body: 400,
    heading: 600,

    light: 200,
    medium: 500,
    bold: 600
  },

  lineHeights: {
    body: 1.5,
    heading: 1.25
  },

  colors,

  borders: [0, "1px solid", "2px solid"],

  shadows: ["0", "0px 4px 8px rgba(41, 49, 71, 0.1)", "0px 8px 16px rgba(41, 49, 71, 0.1)"],

  text: {
    address: {
      fontFamily: "monospace",
      fontSize: 1
    }
  },

  buttons: {
    primary: {
      ...button,

      bg: baseColors.yellow_200,
      borderColor: baseColors.yellow_200,
      color: "#000000",
      fontSize: 1,

      ":enabled:hover": {
        bg: baseColors.yellow_300,
        borderColor: baseColors.yellow_300
      },
      ":enabled:focus": {
        bg: baseColors.yellow_400,
        borderColor: baseColors.yellow_400
      },
      ":disabled": {
        bg: baseColors.lightGrey,
        borderColor: baseColors.lightGrey,
        cursor: "not-allowed",
        color: baseColors.grey_100
      }
    },
    small: {
      ...button,

      bg: baseColors.lightWhite,
      borderColor: baseColors.yellow_200,
      color: baseColors.brown,
      border: 1,
      fontSize: 0,
      fontWeight: "body",

      ":enabled:hover": {
        bg: baseColors.yellow_100,
        borderColor: baseColors.yellow_200
      },
      ":enabled:focus": {
        bg: baseColors.lightYellow,
        borderColor: baseColors.yellow_200
      },
      ":disabled": {
        bg: baseColors.lightWhite,
        borderColor: baseColors.lightGrey,
        color: baseColors.lightGrey,
        cursor: "not-allowed"
      }
    },

    outline: {
      ...button,
      ...buttonOutline(baseColors.lightYellow, "secondary")
    },

    cancel: {
      ...button,
      bg: baseColors.lightWhite,
      borderColor: baseColors.yellow_200,
      color: baseColors.brown,
      border: 1,
      fontSize: 1,

      ":enabled:hover": {
        bg: baseColors.yellow_100,
        borderColor: baseColors.yellow_200
      },
      ":enabled:focus": {
        bg: baseColors.lightYellow
      },
      ":disabled": {
        bg: baseColors.lightWhite,
        borderColor: baseColors.lightGrey,
        color: baseColors.lightGrey,
        cursor: "not-allowed"
      }
    },

    danger: {
      ...button,

      bg: "danger",
      borderColor: "danger",

      ":enabled:hover": {
        bg: "dangerHover",
        borderColor: "dangerHover"
      }
    },

    icon: {
      ...iconButton,
      color: baseColors.blue,
      ":enabled:hover": { color: "accent" }
    },

    dangerIcon: {
      ...iconButton,
      color: "danger",
      ":enabled:hover": { color: "dangerHover" }
    },

    titleIcon: {
      ...iconButton,
      color: "text",
      ":enabled:hover": { color: "success" }
    }
  },

  cards: {
    primary: {
      ...card,

      padding: 0,

      borderColor: "background",
      bg: baseColors.lightWhite,

      "> h2": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        height: "56px",

        pl: 3,
        py: 2,
        pr: 2,

        fontWeight: "800",

        bg: baseColors.brown,
        fontFamily: "Avenir",

        fontSize: cardHeadingFontSize,
        color: baseColors.white
      }
    },

    info: {
      ...infoCard,

      // display: ["none", "block"]

      display: "block",
      "@media screen and (max-width: 47.9375em)": {
        display: "none"
      }
    },

    infoPopup: {
      ...infoCard,

      position: "fixed",
      top: 0,
      right: 3,
      left: 3,
      mt: "72px",
      height: "80%",
      overflowY: "scroll"
    },

    tooltip: {
      padding: 1,
      // border: 1,
      // borderColor: "muted",
      borderRadius: "4px",
      bg: baseColors.brown,
      boxShadow: 2,
      lineHeight: 1.4,
      fontSize: 1,
      color: baseColors.white,
      fontWeight: "body",
      zIndex: 1
    }
  },

  forms: {
    label: {
      ...formBase
    },

    unit: {
      ...formCell,

      textAlign: "center",
      bg: "muted"
    },

    input: {
      ...formCell,

      flex: 1
    },

    editor: {}
  },

  layout: {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "stretch",

      "@media screen and (min-width: 48em)": {
        borderBottom: `1px solid ${baseColors.brown}`
      },

      position: ["fixed", "relative"],
      width: "100vw",
      top: 0,
      zIndex: 1,

      px: [2, "12px", "12px", 5],
      py: [2, "12px", "12px"],

      ...headerGradient,
      boxShadow: [1, "none"]
    },

    footer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      mt: cardGapY,
      px: 3,
      minHeight: "72px",

      bg: "muted"
    },

    main: {
      width: "100%",
      maxWidth: "1280px",
      mx: "auto",
      mt: ["40px", 0],
      mb: ["40px", "40px"],
      px: cardGapX
    },

    columns: {
      display: "flex",
      flexWrap: "wrap",
      justifyItems: "center"
    },

    left: {
      pr: cardGapX,
      width: ["100%", "58%"]
    },

    right: {
      width: ["100%", "42%"]
    },

    actions: {
      justifyContent: "flex-end",
      mt: 2,

      button: {
        ml: 2
      }
    },

    disabledOverlay: {
      ...overlay,

      bg: "rgba(255, 255, 255, 0.5)"
    },

    modalOverlay: {
      ...modalOverlay,

      bg: "rgba(255, 255, 255, 0.5)",
      zIndex: 999,
      backdropFilter: "blur(7px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },

    modal: {
      padding: 3,
      width: ["100%", "40em"]
    },

    infoOverlay: {
      ...modalOverlay,

      display: "none",
      "@media screen and (max-width: 70em)": {
        display: "block"
      },
      // display: ["block", "none"],

      bg: "rgba(255, 255, 255, 0.8)"
    },

    infoMessage: {
      display: "flex",
      justifyContent: "center",
      m: 3,
      alignItems: "center",
      minWidth: "128px"
    },

    sidenav: {
      // display: ["flex", "none"],
      display: "none",
      "@media screen and (max-width: 70em)": {
        display: "flex"
      },
      flexDirection: "column",
      p: 0,
      m: 0,
      borderColor: "muted",
      mr: "25vw",
      height: "100%",
      ...headerGradient
    },

    badge: {
      border: 0,
      borderRadius: 3,
      p: 1,
      px: 2,
      backgroundColor: "muted",
      color: "slate",
      fontSize: 1,
      fontWeight: "body"
    }
  },

  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",

      height: "100%",

      "#root": {
        height: "100%"
      }
    },

    a: {
      color: baseColors.blue,
      ":hover": { color: "accent" },
      textDecoration: "none",
      fontWeight: "bold"
    }
  },

  links: {
    nav: {
      px: 2,
      py: 1,
      color: baseColors.brown,
      fontWeight: "medium",
      fontSize: 1,
      textTransform: "uppercase",
      letterSpacing: "2px",
      width: ["100%", "auto"],
      mt: [3, "auto"],

      "@media screen and (max-width: 70em)": {
        display: "flex",
        flexDirection: "column",
        mt: 3
      },

      "&.active": {
        color: baseColors.brown,
        fontWeight: "bold"
      }
    }
  }
};

export default theme;
