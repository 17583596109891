import React, { ReactElement } from "react";
import { ChainId } from "../config/web3/chains";

interface Props {
  chainId?: ChainId;
  className?: string;
  margin?: string;
  iconPath?: string;
  size?: number;
}

/**
 *
 * Either @param {ChainId} chainId
 * or @param {string} iconPath should be specified to get an icon path
 *
 */

function NetworkIcon({ className, margin, iconPath, size = 20 }: Props): ReactElement {
  const src = iconPath;

  return (
    <img
      className={className}
      width={size}
      height={size}
      style={{ margin: margin }}
      src={src}
      alt="network_icon"
    />
  );
}

export default NetworkIcon;
