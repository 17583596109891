import { Decimal } from "./Decimal";
import { Fees } from "./Fees";
import { SABLEStake } from "./SABLEStake";
import { StabilityDeposit } from "./StabilityDeposit";
import { Trove, TroveWithPendingRedistribution, UserTrove } from "./Trove";
import { FrontendStatus, ReadableLiquity, TroveListingParams } from "./ReadableLiquity";

/** @internal */
export type _ReadableLiquityWithExtraParamsBase<T extends unknown[]> = {
  [P in keyof ReadableLiquity]: ReadableLiquity[P] extends (...params: infer A) => infer R
    ? (...params: [...originalParams: A, ...extraParams: T]) => R
    : never;
};

/** @internal */
export type _LiquityReadCacheBase<T extends unknown[]> = {
  [P in keyof ReadableLiquity]: ReadableLiquity[P] extends (...args: infer A) => Promise<infer R>
    ? (...params: [...originalParams: A, ...extraParams: T]) => R | undefined
    : never;
};

// Overloads get lost in the mapping, so we need to define them again...

/** @internal */
export interface _ReadableLiquityWithExtraParams<T extends unknown[]>
  extends _ReadableLiquityWithExtraParamsBase<T> {
  getTroves(
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution[]>;

  getTroves(params: TroveListingParams, ...extraParams: T): Promise<UserTrove[]>;
}

/** @internal */
export interface _LiquityReadCache<T extends unknown[]> extends _LiquityReadCacheBase<T> {
  getTroves(
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): TroveWithPendingRedistribution[] | undefined;

  getTroves(params: TroveListingParams, ...extraParams: T): UserTrove[] | undefined;
}

/** @internal */
export class _CachedReadableLiquity<T extends unknown[]>
  implements _ReadableLiquityWithExtraParams<T> {
  private _readable: _ReadableLiquityWithExtraParams<T>;
  private _cache: _LiquityReadCache<T>;

  constructor(readable: _ReadableLiquityWithExtraParams<T>, cache: _LiquityReadCache<T>) {
    this._readable = readable;
    this._cache = cache;
  }

  async getTotalRedistributed(...extraParams: T): Promise<Trove> {
    return (
      this._cache.getTotalRedistributed(...extraParams) ??
      this._readable.getTotalRedistributed(...extraParams)
    );
  }

  async getTroveBeforeRedistribution(
    address?: string,
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution> {
    return (
      this._cache.getTroveBeforeRedistribution(address, ...extraParams) ??
      this._readable.getTroveBeforeRedistribution(address, ...extraParams)
    );
  }

  async getTrove(address?: string, ...extraParams: T): Promise<UserTrove> {
    const [troveBeforeRedistribution, totalRedistributed] = await Promise.all([
      this.getTroveBeforeRedistribution(address, ...extraParams),
      this.getTotalRedistributed(...extraParams)
    ]);

    return troveBeforeRedistribution.applyRedistribution(totalRedistributed);
  }

  async getNumberOfTroves(...extraParams: T): Promise<number> {
    return (
      this._cache.getNumberOfTroves(...extraParams) ??
      this._readable.getNumberOfTroves(...extraParams)
    );
  }
  
  // start dev custom const params
  async getStateBorrowingFeeFloor(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateBorrowingFeeFloor(...extraParams) ??
      this._readable.getStateBorrowingFeeFloor(...extraParams)
    )
  }

  async getStateCCR(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateCCR(...extraParams) ??
      this._readable.getStateCCR(...extraParams)
    )
  }

  async getStateUSDSGasCompensation(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateUSDSGasCompensation(...extraParams) ??
      this._readable.getStateUSDSGasCompensation(...extraParams)
    )
  }

  async getStateMCR(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateMCR(...extraParams) ??
      this._readable.getStateMCR(...extraParams)
    )
  }

  async getStateMinNetDebt(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateMinNetDebt(...extraParams) ??
      this._readable.getStateMinNetDebt(...extraParams)
    )
  }

  async getStateRedemptionFeeFloor(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getStateRedemptionFeeFloor(...extraParams) ??
      this._readable.getStateRedemptionFeeFloor(...extraParams)
    )
  }
  //end

  async getPrice(...extraParams: T): Promise<Decimal> {
    return this._cache.getPrice(...extraParams) ?? this._readable.getPrice(...extraParams);
  }

  async getTotal(...extraParams: T): Promise<Trove> {
    return this._cache.getTotal(...extraParams) ?? this._readable.getTotal(...extraParams);
  }

  async getStabilityDeposit(address?: string, ...extraParams: T): Promise<StabilityDeposit> {
    return (
      this._cache.getStabilityDeposit(address, ...extraParams) ??
      this._readable.getStabilityDeposit(address, ...extraParams)
    );
  }

  async getRemainingStabilityPoolSABLEReward(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getRemainingStabilityPoolSABLEReward(...extraParams) ??
      this._readable.getRemainingStabilityPoolSABLEReward(...extraParams)
    );
  }

  async getUSDSInStabilityPool(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getUSDSInStabilityPool(...extraParams) ??
      this._readable.getUSDSInStabilityPool(...extraParams)
    );
  }

  async getUSDSBalance(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getUSDSBalance(address, ...extraParams) ??
      this._readable.getUSDSBalance(address, ...extraParams)
    );
  }

  async getSABLEBalance(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getSABLEBalance(address, ...extraParams) ??
      this._readable.getSABLEBalance(address, ...extraParams)
    );
  }

  async getCollateralSurplusBalance(address?: string, ...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getCollateralSurplusBalance(address, ...extraParams) ??
      this._readable.getCollateralSurplusBalance(address, ...extraParams)
    );
  }

  getTroves(
    params: TroveListingParams & { beforeRedistribution: true },
    ...extraParams: T
  ): Promise<TroveWithPendingRedistribution[]>;

  getTroves(params: TroveListingParams, ...extraParams: T): Promise<UserTrove[]>;

  async getTroves(params: TroveListingParams, ...extraParams: T): Promise<UserTrove[]> {
    const { beforeRedistribution, ...restOfParams } = params;

    const [totalRedistributed, troves] = await Promise.all([
      beforeRedistribution ? undefined : this.getTotalRedistributed(...extraParams),
      this._cache.getTroves({ beforeRedistribution: true, ...restOfParams }, ...extraParams) ??
        this._readable.getTroves({ beforeRedistribution: true, ...restOfParams }, ...extraParams)
    ]);

    if (totalRedistributed) {
      return troves.map(trove => trove.applyRedistribution(totalRedistributed));
    } else {
      return troves;
    }
  }

  async getFees(...extraParams: T): Promise<Fees> {
    return this._cache.getFees(...extraParams) ?? this._readable.getFees(...extraParams);
  }

  async getSABLEStake(address?: string, ...extraParams: T): Promise<SABLEStake> {
    return (
      this._cache.getSABLEStake(address, ...extraParams) ??
      this._readable.getSABLEStake(address, ...extraParams)
    );
  }

  async getTotalStakedSABLE(...extraParams: T): Promise<Decimal> {
    return (
      this._cache.getTotalStakedSABLE(...extraParams) ??
      this._readable.getTotalStakedSABLE(...extraParams)
    );
  }

  async getFrontendStatus(address?: string, ...extraParams: T): Promise<FrontendStatus> {
    return (
      this._cache.getFrontendStatus(address, ...extraParams) ??
      this._readable.getFrontendStatus(address, ...extraParams)
    );
  }
}
