import { ChainId } from "./web3/chains";

const REWARDS_CHECKER_ADDRESSES: {
  [id in ChainId]: string;
} = {
  [ChainId.BSC_MAINNET]: "0xf7518065729C5a7e5a5b3Ce11939F7FdD50b58F4",
  [ChainId.BSC_TESTNET]: "",
  [ChainId.BASE]: "0xBb2E3df1F4B0F725DE45F2916B3D44dF547995D4",
  [ChainId.BASE_TESTNET]: "0x83d2244e3C1f688179776b9363F3FAbF39D42038"
};

const COMMUNITY_ISSUANCE_ADDRESSES: {
  [id in ChainId]: string;
} = {
  [ChainId.BSC_MAINNET]: "0x7fd517b06b898F1a6081E0891265516F83Dc9C9E",
  [ChainId.BSC_TESTNET]: "",
  [ChainId.BASE]: "0x2e11dE9c8A8B4881c5b80c68FAeE2Ceb20644cFf",
  [ChainId.BASE_TESTNET]: "0x0D7b789fe18281766D213bA5E4FFb8aDe57CeB47"
};

const STAKED_LPPOOLTVL_CHECKER_ADDRESSES: {
  [id in ChainId]: string;
} = {
  [ChainId.BSC_MAINNET]: "0xB45fc002Ccf1BED4adD10e0E95D40d7D7Af63331",
  [ChainId.BSC_TESTNET]: "",
  [ChainId.BASE]: "", // TODO: Change to actual address
  [ChainId.BASE_TESTNET]: "0x601D5eA32EEEdEb0d7Cc5c3D0090bEBD200DD752"
};

function getAddressFromChains(
  map: {
    [id in ChainId]: string;
  },
  chainId: ChainId
): string | null {
  const address = map[chainId];
  if (!address) {
    return null;
  }
  return address;
}

export function getRewardsCheckerAddr(chainId: ChainId): string | null {
  return getAddressFromChains(REWARDS_CHECKER_ADDRESSES, chainId);
}

export function getCommunityIssuanceAddr(chainId: ChainId): string | null {
  return getAddressFromChains(COMMUNITY_ISSUANCE_ADDRESSES, chainId);
}

export function getStakedLPTVLCheckerAddr(chainId: ChainId): string | null {
  return getAddressFromChains(STAKED_LPPOOLTVL_CHECKER_ADDRESSES, chainId);
}
