import { Box, Flex, Heading, Text } from "theme-ui";

import { Icon } from "./Icon";

type InfoMessageProps = {
  title: string;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export const InfoMessage: React.FC<InfoMessageProps> = ({ title, children, icon }) => (
  <Box sx={{ mx: 1, mb: 3 }}>
    <Flex sx={{ alignItems: "center", mb: "10px" }}>
      <Box sx={{ mr: "12px", fontSize: "20px" }}>{icon || <Icon name="info-circle" />}</Box>

      <Heading sx={{ color: "#804C3B", fontSize: 2, fontWeight: "medium" }} as="h3">
        {title}
      </Heading>
    </Flex>

    <Text sx={{ fontSize: 2, color: "#804C3B" }}>{children}</Text>
  </Box>
);
