import { Card, Heading, Box, Flex, Button, Link } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useStakingView } from "./context/StakingViewContext";
import useChain from "../../hooks/useChain";
import { Yield } from "./Yield";

export const NoStake: React.FC = () => {
  const { dispatch } = useStakingView();
  const chain = useChain();
  const lpSymbol = chain.isStakeLPSupported ? `SABLE-${chain.nativeCurrency.symbol}` : "SABLE";

  return (
    <Card>
      <Heading>Staking</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title={`You haven't staked ${lpSymbol} yet.`}>
          Stake {lpSymbol} to earn a share of borrowing and redemption fees
          {chain.isStakeLPSupported ? ", and LP trading fees on DEX." : "."}
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", gap: "0.25rem" }}>
            Learn more:
            <Link
              sx={{ fontSize: 2 }}
              href="https://sable-finance.gitbook.io/docs/sable-and-usds/token-summary#staking-sable"
              target="_blank"
            >
              {lpSymbol} staking
            </Link>
          </Flex>
        </InfoMessage>

        <Flex variant="layout.actions">
          {chain.isStakeLPSupported && (
            <Flex sx={{ justifyContent: "flex-start", flex: 1, alignItems: "center" }}>
              <Yield />
            </Flex>
          )}
          <Button onClick={() => dispatch({ type: "startAdjusting" })}>Start staking</Button>
        </Flex>
      </Box>
    </Card>
  );
};
