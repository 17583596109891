import React from "react";
import { Card, Paragraph, Text } from "theme-ui";
import { InfoIcon } from "../InfoIcon";
import { Badge } from "../Badge";
import useSPRewardsAPR from "../../hooks/useSPRewardsAPR";


export const Yield: React.FC = () => {
  const { stabilityPoolAPR } = useSPRewardsAPR();
  return (
    <Badge>
      <Text>SABLE APR {stabilityPoolAPR}%</Text>
      <InfoIcon
        tooltip={
          <Card variant="tooltip" >
            <Paragraph>
              An <Text sx={{ fontWeight: "bold" }}>estimate</Text> of the SABLE return on the USDS
              deposited to the Stability Pool over the next year, not including your BNB gains from
              liquidations.
            </Paragraph>
          </Card>
        }
      ></InfoIcon>
    </Badge>
  );
};
