import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ChainId, DEFAULT_CHAIN_ID, rpcUrls } from "./chains";
import { AbstractConnector } from "@web3-react/abstract-connector";
import MetaMaskLogo from "../../assets/icons/metamask-icon.svg";
import WalletConnectLogo from "../../assets/icons/walletconnect-icon.svg";
import TrustWalletLogo from "../../assets/icons/trustwallet-icon.svg";
import BinanceLogo from "../../assets/icons/binance-icon.svg";
import MetaMaskWithTextLogo from "../../assets/icons/metamask-group.svg";
import WalletConnectWithTextLogo from "../../assets/icons/walletconnect-group.svg";
import TrustWalletWithTextLogo from "../../assets/icons/trustwallet-group.svg";
import BinanceWithTextLogo from "../../assets/icons/binance-group.svg";

export enum WalletId {
  METAMASK = "METAMASK",
  WALLETCONNECT = "WALLETCONNECT",
  TRUSTWALLET = "TRUSTWALLET",
  BINANCEWALLET = "BINANCEWALLET"
}
type Props = {
  id: WalletId;
  connector: AbstractConnector;
  name: string;
  icon: string;
  iconWithText: { src: string; height: number };
};
class Wallet {
  readonly connector: AbstractConnector;
  readonly name: string;
  readonly id: WalletId;
  readonly icon: string;
  readonly iconWithText: Props["iconWithText"];
  constructor({ id, connector, icon, name, iconWithText }: Props) {
    this.id = id;
    this.connector = connector;
    this.icon = icon;
    this.name = name;
    this.iconWithText = iconWithText;
  }
}

export const Wallets: { [id in WalletId]: Wallet } = {
  [WalletId.METAMASK]: new Wallet({
    id: WalletId.METAMASK,
    connector: new InjectedConnector({}),
    icon: MetaMaskLogo,
    iconWithText: { src: MetaMaskWithTextLogo, height: 25 },
    name: "MetaMask"
  }),
  [WalletId.WALLETCONNECT]: new Wallet({
    id: WalletId.WALLETCONNECT,
    connector: new WalletConnectConnector({
      rpc: Object.entries(rpcUrls).reduce<{ [id in ChainId]: string }>((prev, acc) => {
        return { ...prev, [acc[0]]: acc[1][0] };
      }, {} as { [id in ChainId]: string }),
      chainId: DEFAULT_CHAIN_ID,
      bridge: "https://bridge.walletconnect.org",
      qrcode: true
    }),
    icon: WalletConnectLogo,
    iconWithText: { src: WalletConnectWithTextLogo, height: 22 },
    name: "WalletConnect"
  }),
  [WalletId.TRUSTWALLET]: new Wallet({
    id: WalletId.TRUSTWALLET,
    connector: new InjectedConnector({}),
    icon: TrustWalletLogo,
    iconWithText: { src: TrustWalletWithTextLogo, height: 35 },
    name: "TrustWallet"
  }),
  [WalletId.BINANCEWALLET]: new Wallet({
    id: WalletId.BINANCEWALLET,
    connector: new InjectedConnector({}),
    icon: BinanceLogo,
    iconWithText: { src: BinanceWithTextLogo, height: 30 },
    name: "BinanceWallet"
  })
};
