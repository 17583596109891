import React from "react";
import { Button, Flex } from "theme-ui";

import {
  Decimal,
  Decimalish,
  LiquityStoreState,
  SABLEStake,
  SABLEStakeChange
} from "@liquity/lib-base";

import { LiquityStoreUpdate, useLiquityReducer } from "@liquity/lib-react";
import { COIN } from "../../strings";
import { useStakingView } from "./context/StakingViewContext";
import { StakingEditor } from "./StakingEditor";
import { StakingManagerAction } from "./StakingManagerAction";
import { ActionDescription, Amount } from "../ActionDescription";
import { ErrorDescription } from "../ErrorDescription";
import { useStakingContext } from "./context/StakingContext";
import GetSableBnbLpButton from "./GetSableBnbLpButton";
import useChain from "../../hooks/useChain";

const init = ({ sableStake }: LiquityStoreState) => ({
  originalStake: sableStake,
  editedSABLE: sableStake.stakedSABLE
});

type StakeManagerState = ReturnType<typeof init>;
type StakeManagerAction =
  | LiquityStoreUpdate
  | { type: "revert" }
  | { type: "setStake"; newValue: Decimalish };

const reduce = (state: StakeManagerState, action: StakeManagerAction): StakeManagerState => {
  // console.log(state);
  // console.log(action);

  const { originalStake, editedSABLE } = state;

  switch (action.type) {
    case "setStake":
      return { ...state, editedSABLE: Decimal.from(action.newValue) };

    case "revert":
      return { ...state, editedSABLE: originalStake.stakedSABLE };

    case "updateStore": {
      const {
        stateChange: { sableStake: updatedStake }
      } = action;

      if (updatedStake) {
        return {
          originalStake: updatedStake,
          editedSABLE: updatedStake.apply(originalStake.whatChanged(editedSABLE))
        };
      }
    }
  }

  return state;
};

type StakingManagerActionDescriptionProps = {
  originalStake: SABLEStake;
  change: SABLEStakeChange<Decimal>;
};

const StakingManagerActionDescription: React.FC<StakingManagerActionDescriptionProps> = ({
  originalStake,
  change
}) => {
  const chain = useChain();
  const lpSymbol = `SABLE-${chain.nativeCurrency.symbol}`;
  const stakeSABLE = change.stakeSABLE?.prettify().concat(" ", lpSymbol);
  const unstakeSABLE = change.unstakeSABLE?.prettify().concat(" ", lpSymbol);
  const collateralGain = originalStake.collateralGain.nonZero?.prettify(4).concat(" BNB");
  const usdsGain = originalStake.usdsGain.nonZero?.prettify().concat(" ", COIN);

  if (originalStake.isEmpty && stakeSABLE) {
    return (
      <ActionDescription>
        You are staking <Amount>{stakeSABLE}</Amount>.
      </ActionDescription>
    );
  }

  return (
    <ActionDescription>
      {stakeSABLE && (
        <>
          You are adding <Amount>{stakeSABLE}</Amount> to your stake
        </>
      )}
      {unstakeSABLE && (
        <>
          You are withdrawing <Amount>{unstakeSABLE}</Amount> to your wallet
        </>
      )}
      {(collateralGain || usdsGain) && (
        <>
          {" "}
          and claiming{" "}
          {collateralGain && usdsGain ? (
            <>
              <Amount>{collateralGain}</Amount> and <Amount>{usdsGain}</Amount>
            </>
          ) : (
            <>
              <Amount>{collateralGain ?? usdsGain}</Amount>
            </>
          )}
        </>
      )}
      .
    </ActionDescription>
  );
};

export const StakingManager: React.FC = () => {
  const chain = useChain();
  const lpSymbol = chain.isStakeLPSupported ? `SABLE-${chain.nativeCurrency.symbol}` : "SABLE";
  const { dispatch: dispatchStakingViewAction } = useStakingView();
  /** @todo should rename editedSABLE as editedSableBnbLp */
  const [{ originalStake, editedSABLE }, dispatch] = useLiquityReducer(reduce, init);
  const {
    userStakingData: { lpBalance: sableBnbLpBalance }
  } = useStakingContext();
  /** @todo update properties' names in change variable. should use sableBnbLp */
  const change = originalStake.whatChanged(editedSABLE);
  const [validChange, description] = !change
    ? [undefined, undefined]
    : change.stakeSABLE?.gt(sableBnbLpBalance)
    ? [
        undefined,
        <ErrorDescription>
          The amount you're trying to stake exceeds your balance by{" "}
          <Amount>
            {change.stakeSABLE.sub(sableBnbLpBalance).prettify()} {lpSymbol}
          </Amount>
          .
        </ErrorDescription>
      ]
    : [change, <StakingManagerActionDescription originalStake={originalStake} change={change} />];

  const makingNewStake = originalStake.isEmpty;

  return (
    <StakingEditor title={"Staking"} {...{ originalStake, editedSABLE, dispatch }}>
      {description ??
        (makingNewStake ? (
          <ActionDescription>Enter the amount of {lpSymbol} you'd like to stake.</ActionDescription>
        ) : (
          <ActionDescription>Adjust the {lpSymbol} amount to stake or withdraw.</ActionDescription>
        ))}

      <Flex variant="layout.actions">
        <GetSableBnbLpButton />
        <Button
          variant="cancel"
          onClick={() => dispatchStakingViewAction({ type: "cancelAdjusting" })}
        >
          Cancel
        </Button>

        {validChange ? (
          <StakingManagerAction change={validChange}>Confirm</StakingManagerAction>
        ) : (
          <Button disabled>Confirm</Button>
        )}
      </Flex>
    </StakingEditor>
  );
};
