import React, { useCallback, useRef, useState } from "react";
import { Text, Flex, Box, Heading } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../strings";
import { useLiquity } from "../hooks/LiquityContext";
import { shortenAddress } from "../utils/shortenAddress";
import { Web3Provider } from "@ethersproject/providers";

import { Icon } from "./Icon";
import { CHAINS, ChainId, SUPPORTED_CHAIN_IDS } from "../config/web3/chains";
import NetworkIcon from "./NetworkIcon";
import styled, { css } from "styled-components";
import { useWeb3React } from "@web3-react/core";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useChain from "../hooks/useChain";

const NetworkName = styled(Text).attrs(() => ({
  variant: "subtitle1"
}))`
  font-weight: 600;
  color: #804c3b;
  font-size: 14px;
  letter-spacing: 0.5px;
`;

type NetworkItemProps = {
  selected: boolean;
};

const NetworkItem = styled.div<NetworkItemProps>`
  ${({ theme, selected }) => css`
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 4px;
    cursor: pointer;
    color: #804c3b;

    @media (min-width: 48em) {
      padding: 8px;
    }

    &.bsc:hover {
      img {
        filter: brightness(0.2);
      }
    }

    &:hover {
      background: #e5e5e5;
      ${NetworkName} {
        color: #000;
      }
    }

    ${selected &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background: #f0b90b;
      ${NetworkName} {
        color: #000;
      }

      &.bsc {
        img {
          filter: brightness(0.2);
        }
      }
    `}
  `}
`;

const NetworkItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;

const select = ({ accountBalance, usdsBalance, sableBalance }: LiquityStoreState) => ({
  accountBalance,
  usdsBalance,
  sableBalance
});

export const UserAccount: React.FC = () => {
  const { account } = useLiquity();
  const { library, chainId: currentChainId } = useWeb3React<Web3Provider>();
  const chain = useChain();

  const { accountBalance, usdsBalance, sableBalance } = useLiquitySelector(select);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useOnClickOutside(ref, () => setIsOpen(false));

  const currencySymbol = chain.nativeCurrency.symbol;

  const switchNetwork = useCallback(
    async (chainId: ChainId) => {
      if (library && library.provider && library.provider.request) {
        try {
          await library.provider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${chainId.toString(16)}` }]
          });
        } catch (e: any) {
          if (e.code === 4902) {
            try {
              await library.provider.request({
                method: "wallet_addEthereumChain",
                params: [CHAINS[chainId].getAddEthereumChainParameters()]
              });
            } catch (addError) {
              console.error(addError);
            }
          }
          console.error(e);
        }
      }
    },
    [library]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
      }}
    >
      <Flex
        sx={{
          position: "relative",
          alignItems: "center",
          mr: 3,
          p: "8px 6px",
          backgroundColor: "#fff",
          border: "1px solid #f0b90b",
          borderRadius: "4px"
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <NetworkIcon iconPath={chain.networkIcon} />
        <Flex sx={{ ml: 2, flexDirection: "column" }}>
          <Heading sx={{ fontSize: 1 }}>{shortenAddress(account)}</Heading>
        </Flex>

        {isOpen && (
          <Box
            ref={ref}
            sx={{
              position: "absolute",
              top: "calc(100% + 15px)",
              left: 0,
              width: "180px",
              backgroundColor: "#fff",
              boxShadow: "0px 4px 12px 0px rgba(0,0,0,0.25)"
            }}
          >
            <NetworkItems>
              {SUPPORTED_CHAIN_IDS.map(chainId => {
                const { name, networkIcon } = CHAINS[chainId];

                return (
                  <NetworkItem
                    key={chainId}
                    className={
                      chainId === ChainId.BSC_MAINNET || chainId === ChainId.BSC_TESTNET ? "bsc" : ""
                    }
                    onClick={() => {
                      switchNetwork(chainId);
                      setIsOpen(false);
                    }}
                    selected={(currentChainId as ChainId) === chainId}
                  >
                    <NetworkIcon iconPath={networkIcon} margin="0 8px 0 0" size={24} />
                    <NetworkName as="span"> {name}</NetworkName>
                  </NetworkItem>
                );
              })}
            </NetworkItems>
          </Box>
        )}
      </Flex>

      <Flex
        sx={{
          alignItems: "center",
          "@media screen and (max-width: 47.9375em)": {
            display: "none"
          }
        }}
      >
        <Icon name="wallet" size="lg" />

        {(
          [
            [currencySymbol, accountBalance],
            [COIN, usdsBalance],
            [GT, sableBalance]
          ] as const
        ).map(([currency, balance], i) => (
          <Flex key={i} sx={{ ml: 3, flexDirection: "column" }}>
            <Heading sx={{ fontSize: 1 }}>{currency}</Heading>
            <Text sx={{ fontSize: 1 }}>{balance.prettify()}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};
