import { BigNumber, ethers, utils } from "ethers";
import { useEffect, useState } from "react";
import { getPriceFeed } from "@liquity/lib-ethers";
import { usePoller } from "./usePoller";
import useLocalStorage from "./useLocalStorage";
import { useWeb3React } from "@web3-react/core";
import { ChainId } from "../config/web3/chains";

/**
 * Configs
 * */
const SABLE_BNB_PAIR_ADDRESS = "0xa0D4e270D9EB4E41f7aB02337c21692D7eECCCB0";
const NODE_REAL_PANCAKESWAP_V2_EXCHANGE_ENDPOINT = `https://data-platform.nodereal.io/graph/v1/${process.env.REACT_APP_NODE_REAL_API_KEY}/projects/pancakeswap`;
const SABLE_BNB_PAIR_CONTRACT = (() => {
  // Only support mainnet
  const provider = new ethers.providers.JsonRpcProvider("https://binance.llamarpc.com");
  return new ethers.Contract(
    SABLE_BNB_PAIR_ADDRESS,
    [
      "function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)"
    ],
    provider
  );
})();

export type PancakeSwapPool2Data = {
  lpfeeApr: number | null;
};
type PancakeSwapExchangeData = {
  data: { pairDayDatas: { date: number; dailyVolumeUSD: string }[] };
};

type PancakeSwapPairReserve = {
  /** reserve0 is SABLE */
  _reserve0: BigNumber;
  /** reserve1 is WBNB */
  _reserve1: BigNumber;
};

function usePancakeSwapPool2Data(): PancakeSwapPool2Data {
  const { chainId } = useWeb3React();

  const [lpfeeApr, setLpfeeApr] = useState<number | null>(null);
  const [bnbPrice, setBnbPrice] = useState<number | null>(null);
  const [reserves, setReserves] = useState<PancakeSwapPairReserve>({
    _reserve0: ethers.constants.Zero,
    _reserve1: ethers.constants.Zero
  });
  const [
    {
      nextUpdateTimestamp,
      data: { annualTradingFeeDistributedToLp }
    },
    setPancakeSwapExchangeData
  ] = useLocalStorage<{
    nextUpdateTimestamp: number;
    data: { annualTradingFeeDistributedToLp: number | null };
  }>({
    key: "pancakeswap-exchange-data",
    initialValue: {
      nextUpdateTimestamp: 0,
      data: {
        annualTradingFeeDistributedToLp: null
      }
    }
  });

  useEffect(() => {
    const fetchExchangeData = async () => {
      try {
        if (chainId === ChainId.BSC_MAINNET) {
          // the rate limit would be up to 200 queries per day per user
          const res = await fetch(NODE_REAL_PANCAKESWAP_V2_EXCHANGE_ENDPOINT, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json"
            },
            // the first data is not a full day. dailyVolumeUSD is completed at every 0:00 (UTC).
            body: JSON.stringify({
              query: `{
                pairDayDatas(where: {pairAddress: "${SABLE_BNB_PAIR_ADDRESS}"} skip: 1 first: 7) {
                  date
                  dailyVolumeUSD
              }
            }`
            })
          });
          const { data } = (await res.json()) as PancakeSwapExchangeData;
          if (data.pairDayDatas) {
            const pairDayDatas = data.pairDayDatas;
            const weeklyVolumeUsd = pairDayDatas.reduce((sum, { dailyVolumeUSD }) => {
              sum += +dailyVolumeUSD;
              return sum;
            }, 0);
            // As SABLE-BNB pair uses PancakeSwap V2, 0.17% fee is added back to the Liquidity Pool in a form of trading fees
            // This annual trading fee is based on last 7 day volume.
            const annualTradingFeeDistributedToLp = weeklyVolumeUsd * (0.17 / 100) * (365 / 7);
            const currentDate = new Date(Date.now());
            const nextUpdateDate = new Date(currentDate);
            nextUpdateDate.setUTCDate(currentDate.getUTCDate() + 1);
            //  dailyVolumeUSD is updated every 0:00 (UTC).
            nextUpdateDate.setUTCHours(0, 0, 0, 0);
            setPancakeSwapExchangeData({
              nextUpdateTimestamp: nextUpdateDate.getTime(),
              data: { annualTradingFeeDistributedToLp }
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (Date.now() > nextUpdateTimestamp) {
      fetchExchangeData();
    }
    // no need to put nextUpdateTimestamp as dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setPancakeSwapExchangeData]);

  /**
   * Fetch bnb price from coingecko
   */
  usePoller(
    async () => {
      try {
        if (chainId === ChainId.BSC_MAINNET) {
          const priceFeed = await getPriceFeed(chainId);
          setBnbPrice(Number(priceFeed.price.toString()));
        }
      } catch (error) {
        console.log(error);
      }
    },
    [],
    120000 // 2 min
  );

  /**
   * Try to listen on Sync event which means Reserves are updated. However, Sync event is kept being emitted.
   * That's why use usePoller instead.
   */
  usePoller(
    async () => {
      try {
        if (chainId === ChainId.BSC_MAINNET) {
          const reserves = (await SABLE_BNB_PAIR_CONTRACT.getReserves()) as PancakeSwapPairReserve;
          setReserves(reserves);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [],
    60000 // 1 min
  );

  /**
   * Calculate lp fee apr
   */
  useEffect(() => {
    if (
      chainId !== ChainId.BSC_MAINNET ||
      !bnbPrice ||
      reserves._reserve1.isZero() ||
      !annualTradingFeeDistributedToLp
    )
      return;
    const reserve1Usd = reserves._reserve1
      .mul(utils.parseEther(bnbPrice.toString()))
      .div(BigNumber.from(ethers.constants.WeiPerEther));

    // multiply BNB reserve in USD by 2 as the USD values of both reserves should be the same.
    const liquidityUsd = +utils.formatEther(reserve1Usd.mul(2));

    setLpfeeApr((annualTradingFeeDistributedToLp / liquidityUsd) * 100);
  }, [annualTradingFeeDistributedToLp, bnbPrice, chainId, reserves._reserve1]);
  return { lpfeeApr };
}

export default usePancakeSwapPool2Data;
