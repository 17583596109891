import { useEffect, useState } from "react";
import { utils } from "ethers";
import { ChainId } from "../config/web3/chains";
import { useContract } from "./useContract";
import { useWeb3React } from "@web3-react/core";
import { getRewardsCheckerAddr } from "../config/addresses";


function useRewardsChecker() {
  const { account: userAddress, chainId } = useWeb3React();
  const rewardsCheckerContract = useContract<any>(
    getRewardsCheckerAddr(chainId as ChainId),
    ["function calcDepositorPendingSABLEGain(address depositor) returns (uint)"]
  );
  const [pendingRewardEstimate, setReward] = useState<number | undefined>(undefined);

  useEffect(() => {
    const fetchExchangeData = async () => {
      try {
        if (rewardsCheckerContract[0]) {
          const userReward =
            await rewardsCheckerContract[0].callStatic.calcDepositorPendingSABLEGain(userAddress);
          const rewardNumber = parseFloat(utils.formatUnits(userReward, 18));
          const roundedReward = Math.round(rewardNumber * 100) / 100;
          setReward(roundedReward);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchExchangeData();

    const interval = setInterval(fetchExchangeData, 60000); // Fetch data every 1 minute

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, [rewardsCheckerContract, userAddress]);

  return { pendingRewardEstimate }; // Return the reward state
}

export default useRewardsChecker;
