import React, { useCallback } from "react";
import { Card, Heading, Box, Flex, Button, Link } from "theme-ui";
import { InfoMessage } from "../InfoMessage";
import { useTroveView } from "./context/TroveViewContext";
import useChain from "../../hooks/useChain";

export const NoTrove: React.FC = props => {
  const { dispatchEvent } = useTroveView();

  const handleOpenTrove = useCallback(() => {
    dispatchEvent("OPEN_TROVE_PRESSED");
  }, [dispatchEvent]);

  const chain = useChain();

  return (
    <Card>
      <Heading>Trove</Heading>
      <Box sx={{ p: [2, 3] }}>
        <InfoMessage title="You haven't borrowed any USDS yet.">
          You can borrow USDS against {chain.nativeCurrency.symbol} collateral by opening a Trove.
          <Flex sx={{ justifyContent: "flex-start", alignItems: "center", gap: "0.25rem" }}>
            Learn more:
            <Link
              sx={{ fontSize: 2 }}
              href="https://sable-finance.gitbook.io/docs/concept-overview/usds-borrowing"
              target="_blank"
            >
              USDS borrowing
            </Link>
          </Flex>
        </InfoMessage>

        <Flex variant="layout.actions">
          <Button onClick={handleOpenTrove}>Open Trove</Button>
        </Flex>
      </Box>
    </Card>
  );
};
