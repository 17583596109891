import React from "react";
import { Card, Heading, Box, Text } from "theme-ui";
import { AddressZero } from "@ethersproject/constants";
import { Decimal, Percent, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import useStakedLPUSDValueChecker from "../hooks/useStakedLPUSDValueChecker";
import { useLiquity } from "../hooks/LiquityContext";
import { Statistic } from "./Statistic";
import * as l from "../lexicon";
import useChain from "../hooks/useChain";

const selectBalances = ({ accountBalance, usdsBalance, sableBalance }: LiquityStoreState) => ({
  accountBalance,
  usdsBalance,
  sableBalance
});

const Balances: React.FC = () => {
  const { accountBalance, usdsBalance, sableBalance } = useLiquitySelector(selectBalances);

  return (
    <Box sx={{ mb: 3 }}>
      <Heading>My Account Balances</Heading>
      <Statistic lexicon={l.BNB}>{accountBalance.prettify(4)}</Statistic>
      <Statistic lexicon={l.USDS}>{usdsBalance.prettify()}</Statistic>
      <Statistic lexicon={l.SABLE}>{sableBalance.prettify()}</Statistic>
    </Box>
  );
};

type SystemStatsProps = {
  variant?: string;
  showBalances?: boolean;
};

const select = ({
  numberOfTroves,
  price,
  total,
  usdsInStabilityPool,
  borrowingRate,
  redemptionRate,
  frontend,
  ccrNumber,
  fees,
  borrowingFeeFloor
}: LiquityStoreState) => ({
  numberOfTroves,
  price,
  total,
  usdsInStabilityPool,
  borrowingRate,
  redemptionRate,
  kickbackRate: frontend.status === "registered" ? frontend.kickbackRate : null,
  ccrNumber,
  fees,
  borrowingFeeFloor
});

export const SystemStats: React.FC<SystemStatsProps> = ({ variant = "info", showBalances }) => {
  const {
    liquity: {
      connection: { frontendTag }
    }
  } = useLiquity();

  const {
    numberOfTroves,
    price,
    usdsInStabilityPool,
    total,
    // borrowingRate,
    kickbackRate,
    ccrNumber,
    fees,
    borrowingFeeFloor
  } = useLiquitySelector(select);

  const { stakedLPTVLInUSD } = useStakedLPUSDValueChecker();

  const usdsInStabilityPoolPct =
    total.debt.nonZero && new Percent(usdsInStabilityPool.div(total.debt));
  const totalCollateralRatioPct = new Percent(total.collateralRatio(price));
  // const borrowingFeePct = new Percent(borrowingRate);
  const borrowingFee = fees.borrowingRate(borrowingFeeFloor);
  const feePct = new Percent(borrowingFee);
  const oracleRate = 0.25;
  const maxFeePct = parseFloat(feePct.toString(10)) + oracleRate;
  const kickbackRatePct = frontendTag === AddressZero ? "100" : kickbackRate?.mul(100).prettify();

  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;

  return (
    <Card {...{ variant }}>
      {showBalances && <Balances />}

      <Heading
        sx={{
          color: "#000000",
          fontFamily: "Avenir",
          fontWeight: "800"
        }}
      >
        Sable Statistics
      </Heading>

      <Heading
        as="h2"
        sx={{
          mt: 3,
          fontWeight: "medium",
          color: "#000000",
          fontFamily: "Avenir",
          fontSize: "16px !important"
        }}
      >
        Protocol
      </Heading>

      <Statistic lexicon={l.BORROW_FEE}>Max. {maxFeePct.toFixed(2)}%</Statistic>

      <Statistic lexicon={l.TVL[chain.id]}>
        {total.collateral.shorten()} <Text sx={{ fontSize: 1 }}>&nbsp;{currencySymbol}</Text>
        <Text sx={{ fontSize: 1 }}>
          &nbsp;(${Decimal.from(total.collateral.mul(price)).shorten()})
        </Text>
      </Statistic>
      <Statistic lexicon={l.TROVES}>{Decimal.from(numberOfTroves).prettify(0)}</Statistic>
      <Statistic lexicon={l.USDS_SUPPLY}>{total.debt.shorten()}</Statistic>
      {usdsInStabilityPoolPct && (
        <Statistic lexicon={l.STABILITY_POOL_USDS}>
          {usdsInStabilityPool.shorten()}
          <Text sx={{ fontSize: 1 }}>&nbsp;({usdsInStabilityPoolPct.toString(1)})</Text>
        </Statistic>
      )}
      <Statistic lexicon={l.STAKED_SABLE[chain.id]}>
        ${Decimal.from(stakedLPTVLInUSD).shorten()}
      </Statistic>
      <Statistic lexicon={l.TCR[chain.id]}>{totalCollateralRatioPct.prettify()}</Statistic>
      <Statistic lexicon={l.RECOVERY_MODE}>
        {total.collateralRatioIsBelowCritical(price, ccrNumber) ? (
          <Box color="danger">Yes</Box>
        ) : (
          "No"
        )}
      </Statistic>
      {}

      <Heading
        as="h2"
        sx={{ mt: 3, fontWeight: "medium", color: "#000000", fontSize: "16px !important" }}
      >
        Frontend
      </Heading>
      {kickbackRatePct && <Statistic lexicon={l.KICKBACK_RATE}>{kickbackRatePct}%</Statistic>}
    </Card>
  );
};
