import bsc_logo from "../../assets/icons/network/bsc-logo.png";
import base_logo from "../../assets/icons/network/base.png";

export enum ChainId {
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  BASE = 8453,
  BASE_TESTNET = 84531
}

const isProductionEnv = process.env.REACT_APP_ENVIRONMENT === "production";
// Change SUPPORTED_CHAINS for interface
export const SUPPORTED_CHAIN_IDS = isProductionEnv
  ? [ChainId.BSC_MAINNET, ChainId.BASE]
  : [ChainId.BSC_MAINNET, ChainId.BSC_TESTNET, ChainId.BASE, ChainId.BASE_TESTNET];

export const DEFAULT_CHAIN_ID = isProductionEnv ? ChainId.BSC_MAINNET : ChainId.BSC_TESTNET;

export const rpcUrls: {
  [id in ChainId]: string[];
} = {
  [ChainId.BSC_MAINNET]: [
    "https://rpc.ankr.com/bsc",
    "https://bscrpc.com/",
    "https://bsc-dataseed1.binance.org/",
    "https://bsc-dataseed2.binance.org/",
    "https://bsc-dataseed3.binance.org/",
    "https://bsc-dataseed4.binance.org/",
    "https://bsc-dataseed1.defibit.io/",
    "https://bsc-dataseed2.defibit.io/",
    "https://bsc-dataseed3.defibit.io/",
    "https://bsc-dataseed4.defibit.io/",
    "https://bsc-dataseed1.ninicoin.io/",
    "https://bsc-dataseed2.ninicoin.io/",
    "https://bsc-dataseed3.ninicoin.io/",
    "https://bsc-dataseed4.ninicoin.io/"
  ],
  [ChainId.BSC_TESTNET]: [
    "https://bsc-testnet.publicnode.com",
    "https://rpc.ankr.com/bsc_testnet_chapel",
    "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-1-s2.binance.org:8545",
    "https://data-seed-prebsc-1-s3.binance.org:8545/",
    "https://data-seed-prebsc-2-s2.binance.org:8545/",
    "https://data-seed-prebsc-2-s3.binance.org:8545/"
  ],
  [ChainId.BASE]: [
    "https://mainnet.base.org",
    "https://base.publicnode.com",
    "https://1rpc.io/base"
  ],
  [ChainId.BASE_TESTNET]: [
    "https://goerli.base.org",
    "https://base-goerli.publicnode.com",
    "https://1rpc.io/base-goerli	"
  ]
};

interface Props {
  name: string;
  id: ChainId;
  networkIcon: string;
  currencyName: string;
  currencySymbol: string;
  currencyDecimals: number;
  blockExplorerUrls: string[];
  isStakeLPSupported: boolean;
}
interface AddEthereumChainParameter {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[]; // Currently ignored.
}

export class Chain {
  readonly name: string;
  readonly id: ChainId;
  readonly rpcUrls: string[];
  readonly networkIcon: string;
  readonly nativeCurrency: { name: string; symbol: string; decimals: number };
  readonly blockExplorerUrls: string[];
  readonly isStakeLPSupported: boolean;

  constructor({
    name,
    id,
    networkIcon,
    currencyName,
    currencySymbol,
    currencyDecimals,
    blockExplorerUrls,
    isStakeLPSupported
  }: Props) {
    this.name = name;
    this.rpcUrls = rpcUrls[id];
    this.id = id;
    this.blockExplorerUrls = blockExplorerUrls;
    this.networkIcon = networkIcon;
    this.isStakeLPSupported = isStakeLPSupported;
    this.nativeCurrency = {
      name: currencyName,
      symbol: currencySymbol,
      decimals: currencyDecimals
    };
  }

  getAddEthereumChainParameters(): AddEthereumChainParameter {
    return {
      chainId: `0x${this.id.toString(16)}`,
      chainName: this.name,
      nativeCurrency: this.nativeCurrency,
      rpcUrls: this.rpcUrls,
      blockExplorerUrls: this.blockExplorerUrls
    };
  }
}

export const CHAINS: {
  [id in ChainId]: Chain;
} = {
  [ChainId.BSC_MAINNET]: new Chain({
    name: "BNB Chain",
    id: ChainId.BSC_MAINNET,
    networkIcon: bsc_logo,
    currencyName: "Binance",
    currencySymbol: "BNB",
    currencyDecimals: 18,
    blockExplorerUrls: ["https://bscscan.com/"],
    isStakeLPSupported: true
  }),
  [ChainId.BSC_TESTNET]: new Chain({
    name: "BNB Testnet",
    id: ChainId.BSC_TESTNET,
    networkIcon: bsc_logo,
    currencyName: "Binance",
    currencySymbol: "BNB",
    currencyDecimals: 18,
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
    isStakeLPSupported: true
  }),
  [ChainId.BASE]: new Chain({
    name: "Base",
    id: ChainId.BASE,
    networkIcon: base_logo,
    currencyName: "Ethereum",
    currencySymbol: "ETH",
    currencyDecimals: 18,
    blockExplorerUrls: ["https://basescan.org/"],
    isStakeLPSupported: false
  }),
  [ChainId.BASE_TESTNET]: new Chain({
    name: "Base Testnet",
    id: ChainId.BASE_TESTNET,
    networkIcon: base_logo,
    currencyName: "Ethereum",
    currencySymbol: "ETH",
    currencyDecimals: 18,
    blockExplorerUrls: ["https://goerli.basescan.org/"],
    isStakeLPSupported: false
  })
};
