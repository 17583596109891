import { BytesLike } from "@ethersproject/bytes";
import axios from "axios";
import { Buffer } from "buffer";
import { ChainId, PYTH_IDS, getBaseUrlPyth } from "./constants";
import { Decimal } from "@liquity/lib-base";
import { convertBigNumberValueToNumber } from "./_utils";

export async function getPriceFeed(
  chainId: number
): Promise<{ priceFeedUpdateData: BytesLike[]; price: Decimal }> {
  const baseUrlPyth = getBaseUrlPyth(chainId as ChainId);
  const idsPriceBN = PYTH_IDS[chainId as ChainId];
  const request = axios.create({
    baseURL: baseUrlPyth
  });

  const price_res = await request.get("/api/latest_price_feeds", {
    params: {
      ids: [idsPriceBN],
      binary: true
    }
  });
  const priceFeedsJson = price_res.data;
  const price = priceFeedsJson[0]?.price?.price;
  const lastPrice = Decimal.from(convertBigNumberValueToNumber(price, 8));

  const latestVaas = priceFeedsJson[0]?.vaa;
  const data = "0x" + Buffer.from(latestVaas, "base64")?.toString("hex");

  return Promise.resolve({
    priceFeedUpdateData: [data],
    price: lastPrice
  });
}
