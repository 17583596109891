import { ChainId } from "./config/web3/chains";

export type Lexicon = {
  term: string;
  description?: string;
  link?: string;
};

export const BORROW_FEE: Lexicon = {
  term: "Borrowing Fee",
  description:
    "The Borrowing Fee is a one-off fee charged as a percentage of the borrowed amount (in USDS) and is part of a Trove's debt. The fee varies between 0.05% and 5% depending on USDS redemption volumes. User may receive a fee discount of up to 0.25% depending on the oracle latency at the time of transaction, the lower the latency, the higher the fee discount"
};

export const TVL: {
  [id in ChainId]: Lexicon;
} = {
  [ChainId.BSC_MAINNET]: {
    term: "TVL",
    description:
      "The Total Value Locked (TVL) is the total value of BNB locked as collateral in the system, given in BNB and USD."
  },
  [ChainId.BSC_TESTNET]: {
    term: "TVL",
    description:
      "The Total Value Locked (TVL) is the total value of BNB locked as collateral in the system, given in BNB and USD."
  },
  [ChainId.BASE]: {
    term: "TVL",
    description:
      "The Total Value Locked (TVL) is the total value of ETH locked as collateral in the system, given in ETH and USD."
  },
  [ChainId.BASE_TESTNET]: {
    term: "TVL",
    description:
      "The Total Value Locked (TVL) is the total value of ETH locked as collateral in the system, given in ETH and USD."
  }
};

export const STAKED_SABLE: {
  [id in ChainId]: Lexicon;
} = {
  [ChainId.BSC_MAINNET]: {
    term: "Staked SABLE-BNB LP",
    description: "The total amount of SABLE that is staked for earning fee revenue."
  },
  [ChainId.BSC_TESTNET]: {
    term: "Staked SABLE-BNB LP",
    description: "The total amount of SABLE that is staked for earning fee revenue."
  },
  [ChainId.BASE]: {
    term: "Staked SABLE",
    description: "The total amount of SABLE that is staked for earning fee revenue."
  },
  [ChainId.BASE_TESTNET]: {
    term: "Staked SABLE",
    description: "The total amount of SABLE that is staked for earning fee revenue."
  }
};

export const TCR: {
  [id in ChainId]: Lexicon;
} = {
  [ChainId.BSC_MAINNET]: {
    term: "Total Collateral Ratio",
    description:
      "The ratio of the Dollar value of the entire system collateral at the current BNB:USD price, to the entire system debt."
  },
  [ChainId.BSC_TESTNET]: {
    term: "Total Collateral Ratio",
    description:
      "The ratio of the Dollar value of the entire system collateral at the current BNB:USD price, to the entire system debt."
  },
  [ChainId.BASE]: {
    term: "Total Collateral Ratio",
    description:
      "The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt."
  },
  [ChainId.BASE_TESTNET]: {
    term: "Total Collateral Ratio",
    description:
      "The ratio of the Dollar value of the entire system collateral at the current ETH:USD price, to the entire system debt."
  }
};

export const RECOVERY_MODE: Lexicon = {
  term: "Recovery Mode",
  description:
    "Recovery Mode is activated when the Total Collateral Ratio (TCR) falls below 150%. When active, your Trove can be liquidated if its collateral ratio is below the TCR. The maximum collateral you can lose from liquidation is capped at 110% of your Trove's debt. Operations are also restricted that would negatively impact the TCR."
};

export const STABILITY_POOL_USDS: Lexicon = {
  term: "USDS in Stability Pool",
  description:
    "The total USDS currently held in the Stability Pool, expressed as an amount and a fraction of the USDS supply."
};

export const KICKBACK_RATE: Lexicon = {
  term: "Kickback Rate",
  description:
    "A rate between 0 and 100% set by the Frontend Operator that determines the fraction of SABLE that will be paid out as a kickback to the Stability Providers using the frontend."
};

export const BNB: Lexicon = {
  term: "BNB"
};

export const USDS: Lexicon = {
  term: "USDS"
};

export const SABLE: Lexicon = {
  term: "SABLE"
};

export const TROVES: Lexicon = {
  term: "Troves",
  description: "The total number of active Troves in the system."
};

export const USDS_SUPPLY: Lexicon = {
  term: "USDS supply",
  description: "The total USDS minted by the Sable Finance."
};
