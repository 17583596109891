import React, { useCallback, useEffect } from "react";
import { Card, Heading, Box, Flex, Button } from "theme-ui";

import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";

import { COIN, GT } from "../../strings";
import { Icon } from "../Icon";
import { LoadingOverlay } from "../LoadingOverlay";
import { useMyTransactionState } from "../Transaction";
import { DisabledEditableRow, StaticRow } from "../Trove/Editor";
import { ClaimAndMove } from "./actions/ClaimAndMove";
import { ClaimRewards } from "./actions/ClaimRewards";
import { useStabilityView } from "./context/StabilityViewContext";
import useRewardsChecker from "../../hooks/useRewardsChecker";
import useSPRewardsAPR from "../../hooks/useSPRewardsAPR";
import BalanceLabel from "../BalanceLabel/BalanceLabel";
import useChain from "../../hooks/useChain";

const selector = ({
  stabilityDeposit,
  trove,
  usdsInStabilityPool,
  usdsBalance
}: LiquityStoreState) => ({
  stabilityDeposit,
  trove,
  usdsInStabilityPool,
  usdsBalance
});

export const ActiveDeposit: React.FC = () => {
  const { dispatchEvent } = useStabilityView();
  const chain = useChain();
  const currencySymbol = chain.nativeCurrency.symbol;

  const { stabilityDeposit, trove, usdsInStabilityPool, usdsBalance } = useLiquitySelector(selector);

  const { pendingRewardEstimate } = useRewardsChecker();

  const { stabilityPoolAPR } = useSPRewardsAPR();

  const poolShare = stabilityDeposit.currentUSDS.mulDiv(100, usdsInStabilityPool);

  const handleAdjustDeposit = useCallback(() => {
    dispatchEvent("ADJUST_DEPOSIT_PRESSED");
  }, [dispatchEvent]);

  const hasReward = !stabilityDeposit.sableReward.isZero;
  const hasGain = !stabilityDeposit.collateralGain.isZero;
  const hasTrove = !trove.isEmpty;

  const transactionId = "stability-deposit";
  const transactionState = useMyTransactionState(transactionId);
  const isWaitingForTransaction =
    transactionState.type === "waitingForApproval" ||
    transactionState.type === "waitingForConfirmation";

  const pendingReward =
    pendingRewardEstimate === undefined
      ? "..."
      : pendingRewardEstimate === 0
      ? stabilityDeposit.sableReward.prettify()
      : pendingRewardEstimate.toString();

  useEffect(() => {
    if (transactionState.type === "confirmedOneShot") {
      dispatchEvent("REWARDS_CLAIMED");
    }
  }, [transactionState.type, dispatchEvent]);

  return (
    <Card>
      <Heading>Stability Pool</Heading>
      <Box sx={{ p: [2, 3] }}>
        <Box>
          <DisabledEditableRow
            label="Deposit"
            trailingLabel={<BalanceLabel balance={usdsBalance.toString()} tokenSymbol="USDS" />}
            inputId="deposit-usds"
            amount={stabilityDeposit.currentUSDS.prettify()}
            unit={COIN}
          />

          <StaticRow
            label="Pool share"
            inputId="deposit-share"
            amount={poolShare.prettify(4)}
            unit="%"
          />
          <StaticRow
            label="Liquidation gain"
            inputId="deposit-gain"
            amount={stabilityDeposit.collateralGain.prettify(4)}
            color={stabilityDeposit.collateralGain.nonZero && "success"}
            unit={currencySymbol}
          />
          {/** hide sable reward. uncomment it when needed */}
          <Flex sx={{ alignItems: "center" }}>
            <StaticRow
              label="Reward"
              inputId="deposit-reward"
              amount={`${pendingReward}`}
              color={stabilityDeposit.sableReward.nonZero && "success"}
              unit={`${GT}`}
              trailingText={`(${stabilityPoolAPR}% APR)`}
              trailingTextColor={"black"}
            />
          </Flex>
        </Box>

        <Flex variant="layout.actions" sx={{ flexWrap: "wrap", rowGap: "4px" }}>
          <Button variant="cancel" onClick={handleAdjustDeposit}>
            <Icon name="pen" size="sm" />
            &nbsp;Adjust
          </Button>

          <ClaimRewards disabled={!hasGain && !hasReward}>
            Claim {currencySymbol} and SABLE
          </ClaimRewards>
          {hasTrove && (
            <ClaimAndMove disabled={!hasGain}>Move {currencySymbol} to Trove</ClaimAndMove>
          )}
        </Flex>
      </Box>

      {isWaitingForTransaction && <LoadingOverlay />}
    </Card>
  );
};
